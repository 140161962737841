.pricing__cont {
  width: 90vw;
  margin: 30px auto;
}

.pricing__h1 {
  font-family: "Rubik", sans-serif;
  font-size: 28px;
  line-height: 34.2px;
  font-weight: 600;
  color: #010e20;
}

.pricing__sub__p {
  color: #010e20a6;
  font-size: 16px;
  line-height: 24px;
  padding-top: 5px;
}

.billing__cont {
  box-shadow: 0px 2px 10px 0px #0000000d;
  padding: 24px 18px;
  border-radius: 8px;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 30px;
}
.billing__price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.billing__price h3 {
  font-size: 18.5px;
  line-height: 26px;
  color-scheme: #010e20;
  font-weight: 500;
}

.billing__price p {
  color: #f2b11b;
  font-size: 44px;
  font-weight: 700;
  line-height: 44px;
}
.billing__price span {
  font-size: 22px;
  font-weight: 500;
}
.billing__price a {
  text-decoration: none;
}
.billing__price button {
  color: #141414;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  width: 100%;
  background: #f0b429;
  border-radius: 8px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.billing__features__list li,
.add__ons__list li {
  display: flex;
  align-items: flex-start;
}

.billing__features__list li::before {
  content: url("../Images/features_icon.svg");
  margin-right: 0.6rem;
}
.add__ons__list li::before {
  content: url("../Images/addon_icon.svg");
  margin-right: 0.6rem;
}

.billing__features__list {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.billing__features__list li p {
  font-size: 15px;
  line-height: 18.8px;
  color: #58677b;
}
.billing__features__list li p span {
  font-weight: 500;
  color: #000000;
}

.billing__features__p {
  color: #58677b;
  font-size: 14px;
  line-height: 20px;
}
.price__text__cont {
  width: 100%;
  max-width: 784px;
  margin: 0 auto;
}
.dynamic__cont {
  margin-top: 35px;
}
.dynamic__cont h2 {
  color: #000000;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.dynamic__cont__p {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  padding-top: 16px;
}
.dynamic__cont h4 {
  color: #000000;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding-top: 30px;
}
.add__ons__list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.add__ons__list li p {
  font-size: 16px;
  line-height: 21px;
  color: #58677b;
}
.add__ons__list li p span {
  display: block;
  color: #000000;
  font-weight: 500;
  margin-top: -3px;
}
@media screen and (min-width: 700px) {
  .pricing__cont {
    margin: 40px auto;
  }
  .pricing__h1 {
    font-size: 34px;
    line-height: 44.2px;
    text-align: center;
  }

  .pricing__sub__p {
    max-width: 500px;
    text-align: center;
    margin: 0 auto;
    padding-top: 8px;
  }
  .billing__cont {
    padding: 27px 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 50px;
    margin-top: 40px;
  }
  .billing__price {
    gap: 30px;
  }
  .dynamic__cont {
    margin-top: 45px;
  }
}

@media screen and (min-width: 800px) {
  .billing__cont {
    gap: 100px;
  }
}

@media screen and (min-width: 1200px) {
  .pricing__cont {
    margin: 50px auto 70px;
  }
  .billing__cont {
    margin-top: 55px;
  }
  .pricing__h1 {
    font-size: 48px;
    line-height: 57px;
    font-weight: 700;
  }
  .dynamic__cont {
    margin-top: 55px;
  }

  .pricing__sub__p {
    max-width: 550px;
    font-size: 20px;
    line-height: 28px;
    padding-top: 16px;
  }
}
