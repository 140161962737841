.integration-workflow-tech-cont {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px 2%;
  margin-top: 30px;
}

.tab-content-title {
  font-size: 20px;
  color: #010e20a6;
  line-height: 28px;
  text-align: center;
}

.integration-workflow-tech {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  width: 49%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.04);
}

.integration-workflow-podio {
  width: 24.5px;
}
.integration-workflow-mysql {
  width: 44px;
}
.integration-workflow-mongodb {
  width: 43px;
}
.integration-workflow-postgres {
  width: 25.6px;
}
.integration-workflow-rightsig {
  width: 20.8px;
}
.integration-workflow-sharefile {
  width: 26.2px;
}
.integration-workflow-json {
  width: 45px;
}
.integration-tech-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.integration-tech-name h2 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  color: #010e20;
}
.integration-tech-status p {
  font-size: 13px;
  font-family: "Rubik", sans-serif;
  margin-bottom: 0;
}

.integration-available {
  color: #27ab83;
}

.integration-soon {
  color: #f0b429;
}

.integration-tech-status {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.integration-tech-status img {
  width: 14px;
}

.integration-more-tech-tab {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  cursor: pointer;
}

.integration-more-tech-tab p,
.integration-more-tech p {
  font-size: 16px;
  line-height: 24px;
  color: #f0b429;
  font-family: "Rubik", sans-serif;
  margin-bottom: 0;
}

.integration-more-tech-tab img,
.integration-more-tech img {
  width: 24px;
  margin-right: 0;
}

.integration-more-tech {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  border: 1px solid #f0b429;
  background: transparent;
  outline: none;
  border-radius: 50px;
  margin: 30px auto 0;
  gap: 4px;
}

@media screen and (min-width: 700px) {
  .integration-workflow-tech {
    width: 32%;
    gap: 16px;
    min-height: 55px;
  }
  .tab-content-title {
    text-align: left;
  }

  .integration-more-tech-tab {
    display: flex;
  }
  .integration-more-tech {
    display: none;
  }
  .integration-tech-name h2 {
    font-size: 16px;
    line-height: 18px;
  }
  .integration-workflow-tech-cont {
    gap: 14px 2%;
  }
  .integration-workflow-podio {
    width: 26.5px;
  }
  .integration-workflow-mysql {
    width: 46px;
  }
  .integration-workflow-mongodb {
    width: 45px;
  }
  .integration-workflow-postgres {
    width: 27.6px;
  }
  .integration-workflow-rightsig {
    width: 22.8px;
  }
  .integration-workflow-sharefile {
    width: 28.2px;
  }
  .integration-workflow-json {
    width: 48px;
  }
}

@media screen and (min-width: 900px) {
  .integration-workflow-podio {
    width: 29.5px;
  }
  .integration-workflow-mysql {
    width: 49px;
  }
  .integration-workflow-mongodb {
    width: 48px;
  }
  .integration-workflow-postgres {
    width: 30.6px;
  }
  .integration-workflow-rightsig {
    width: 25.8px;
  }
  .integration-workflow-sharefile {
    width: 31.2px;
  }
  .integration-workflow-json {
    width: 51px;
  }
  .integration-tech-name h2 {
    font-size: 18px;
    line-height: 20.4px;
  }
}
@media screen and (min-width: 1200px) {
  .integration-workflow-podio {
    width: 35.1px;
  }
  .tab-content-title {
    text-align: left;
    width: 65%;
  }
  .integration-workflow-tech {
    width: 24.25%;
    gap: 16px;
    padding: 11px;
    min-height: 64px;

  }
  .integration-workflow-tech-cont {
    gap: 13px 1%;
  }
  .integration-workflow-mysql {
    width: 58.3px;
  }
  .integration-workflow-mongodb {
    width: 40.3px;
  }
  .integration-workflow-postgres {
    width: 32.9px;
  }
  .integration-workflow-rightsig {
    width: 25.8px;
  }
  .integration-workflow-sharefile {
    width: 32.2px;
  }
  .integration-workflow-json {
    width: 56px;
  }
  .integration-tech-name h2 {
    font-size: 18px;
    line-height: 20.4px;
  }
  .integration-tech-name {
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
}
