.wrapper {
  box-shadow: 0px 4px 74px rgba(31, 36, 49, 0.05);
  border-radius: 5px;
  width: 100%;
  max-width: 1000px;
  padding-bottom: 33px;
}

.contianer {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Inter', sans-serif;
  height: 50px;
  margin-bottom: 28px;
}

.contianer > * p {
  margin: 0 !important;
}

.contianer > div {
  height: inherit;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contianer > div:first-of-type {
  background-color: #f7c948;
  color: #fff;
  clip-path: polygon(0 0, 100% 0, 80% 98%, 0% 100%);
}

.body_container {
  width: 95%;
  margin: 0 auto;
}