/* dialog box */
.dialog-box{
    font-size:16px;
    color:#626262;
}
.dialog-box h3{
    font-weight:600;
    font-size:24px;
}
.dialog-box .Component-root-52{
    padding:5px;
}
.dialog-box .divider{
    margin:32px 0;
}
.dialog-box .form{
    display:grid;
    grid-template-columns:1fr 2fr;
    padding-right:45px;
}
.dialog-box .label{
    padding-top:20px;
}
.dialog-box .label-two{
    padding-top:10px;
}
.dialog-box .actions{
    text-align:right;
    margin:10px 20px 40px 0;
}
