.sidebar .MuiPaper-root {
  background: #121a27;
  color: #fff;
  padding-bottom: 20px;
  padding-right: 10px;
}

/* .sidebar {
  display: block;
}
.sidebarMobile {
  display: none;
} */
.sidebar .material-icons {
  color: #fff;
}

.sidebar .MuiTypography-body1 {
  color: #fff;
  font-size: 14px;
}

.sidebar .MuiList-root {
  border: none;
  position: relative;
}

.sidebar .sidebar__header {
  width: 100%;
  margin-top: 28px;
}

.sidebar .sidebar__header img {
  margin-left: 5px;
}

.sidebar .logo__title {
  font-weight: bold;
  font-size: 18px;
  margin-left: 10px;
}

.sidebar .MenuNode-nested-15 {
  padding-left: 72px !important;
}

.sidebar .MenuNode-subNested-16 {
  padding-left: 80px !important;
}

.sidebar .MenuCollapse-nested-17 {
  padding-left: 72px !important;
}

.sidebar .sidebar__content {
  margin-top: 40px;
}

.sidebar .sidebar__content .subheading {
  font-size: 13px;
  margin-left: 15px;
  margin-bottom: 20px;
}

.sidebar .sidebar__content .list__item {
  font-size: 14px;
  margin-left: 8px;
}

.sidebar .sidebar__content .MuiListItem-root {
  padding: 10px 20px;
  margin: 0 0px;
  border-radius: 0 24px 24px 0;
  padding-left: 20px;
}

.sidebar .sidebar__content .MuiListItem-root.active {
  background: #f7c948;
  border-radius: 0 24px 24px 0;
  padding: 10px 20px;
  margin: 0 0px;
  padding-left: 20px;
}

.sidebar .sidebar__content .MuiListItem-root img {
  width: 20px;
  margin-right: 10px;
}

.sidebar .sidebar__content .MuiListItem-root .file-img {
  width: 17px;
}

.sidebar .sidebar__content .MuiListItem-root:hover {
  background: #f7c948;
  border-radius: 0 24px 24px 0;
  padding: 10px 20px;
}

.sidebar .banner {
  margin-top: 20px;
}

.sidebar .banner img {
  width: 100%;
  margin: 0 auto 10px auto;
}

.sidebar .banner .title {
  color: #f7f7f7;
  font-weight: 600;
  margin-block: 0;
}

.sidebar .banner .description {
  font-size: 14px;
  margin-top: 5px;
}

.sidebar .banner a {
  border: none;
  background: #f7c948;
  border-radius: 3px;
  color: #fff;
  width: 100%;
  padding: 10px;
  display: block;
}

.sidebar .sidebar__content .hidden {
  display: none;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent !important; /* make scrollbar transparent */
}

/* dialog box */
.dialog-box {
  font-size: 16px;
  color: #626262;
}
.dialog-box h3 {
  font-weight: 600;
  font-size: 24px;
}
.dialog-box .Component-root-26 {
  padding: 5px;
}
.dialog-box .divider {
  margin: 32px 0;
}
.dialog-box .form {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-right: 45px;
}
.dialog-box .form .label {
  font-weight: 600;
  padding-top: 15px;
}
.dialog-box .actions {
  text-align: right;
  margin: 40px 45px 0px 0;
}

.profile_email_display {
  font-size: 12px;
  max-width: 200px;
  margin: 5px 10px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menuIconCont {
  display: block;
}

.cancelIcon {
  display: none;
}

@media screen and (max-width: 768px) {
  .sidebar {
    position: absolute;
  }
  .menuIconCont {
    display: none;
  }
  .cancelIcon {
    display: flex;
  }
  input {
    margin-bottom: 0 !important;
  }
  .sidebar .MuiPaper-root {
    padding-right: 0 !important;
  }
}
