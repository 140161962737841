.container {
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 20px;

  .card_brand {
    width: 70px;
    height: 51px;
  }

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    .mask {
      text-align: center;
      font-weight: bold;
      color: #515151;
    }

    &.created {
      color: #7e7e7e;
    }
  }
}
