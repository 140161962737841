.confirm__modal {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: rgba(7, 7, 7, 0.76);
}

.confirm__modal .modal__content {
  display: flex;
  justify-content: center;
  height: 100%;
  color: #fff;
  align-items: center;
}

.confirm__modal .modal__content .close__modal {
  margin: 10px 0;
  cursor: pointer;
}

.confirm__modal .modal__content .modal__title {
  margin: 10px 0;
  color: #fcfcfc;
}

.confirm__modal .modal__content .modal__card {
  background: #fff;
  width: 100%;
  border-radius: 10px;
  padding: 20px 20px;
  color: #3b3b3b;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;

}
.confirm__modal .modal__content .modal__card P {
  margin-top: 10px;
}

.confirm__modal .modal__content .modal__card .confirm__header {
  color: #03293d;
  text-align: center;
  font-size: 20px;
  margin: 40px 0;
}

.confirm__modal .modal__content .modal__card button {
  /* margin-top: 20px; */
  background: #F0B429;
  color: #fff;
  border: none;
  width: 40%;
  padding: 15px 30px;
  border-radius: 4px;
}

.confirm__modal .modal__content .modal__card .cancel {
  color: #ef4e4e;
  /* margin-top: 30px; */
  cursor: pointer;
}

.confirm__modal .modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f0f4;
  padding: 3% 0;
  width: 400px;
}

.confirm__modal .form-group{
  text-align: left;
  padding: 5% 0;
}

.confirm__modal .modal__footer{
  text-align: right;
}
.for_message{
  width: 350px !important;
}

@media screen and (max-width: 768px) {
 .confirm__modal .modal__content .modal__card {
    width: 85vw;
  }
 .confirm__modal .modal__content .modal__card button {
    width: 50%;
    padding: 10px 20px;
  }

  .confirm__modal .modal__header h4 {
    font-size: 14px;
  }
  .confirm__modal .modal__header {
    width: auto !important;
  }
  .info_Gdpr_modal{
    width: auto !important;
  }
}

