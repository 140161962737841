.override div.MuiDialog-container.MuiDialog-scrollPaper > div {
  background-color: #fff;
}

.container {
  width: 700px;
}

.titleContainer {
  padding: 10px 0;
  color: #000;
  border-bottom: 1px solid #b1b1b1;

  display: flex;
  align-items: center;

  h6 {
    margin-right: 20px;
  }
}

.contentContainer {
  padding: 0px 20px 20px 20px;
  min-height: 500px;
  max-height: 800px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.file_details_container {
  display: flex;
  gap: 25px;
  margin-bottom: 16px;

  p {
    margin-bottom: 6px;
  }

  img {
    width: 80px;
    height: 100px;
  }
}

.recipient_container {
  p {
    border: 1px solid #b1b1b1;
    display: flex;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 0;
    font-weight: 500;

    &:not(:last-of-type) {
      border-bottom: 0;
    }

    span {
      margin-left: auto;
    }
  }
}

.pending {
  color: #f7c948;
  text-transform: capitalize;
}

.completed {
  color: #10aa50;
  text-transform: capitalize;
}

.download_link {
  color: #f7c948;
  font-weight: 500;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;

  &:hover,
  &:active,
  &:visited {
    color: #f7c948;
  }
}

.activity_container {
  p {
    border: 1px solid #b1b1b1;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 0;
    font-weight: 500;

    &:not(:last-of-type) {
      border-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 100px;
    }

    span {
      margin-left: auto;
    }
  }
}
