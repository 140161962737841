.override div.MuiDialog-container.MuiDialog-scrollPaper > div {
  background-color: #fff;
}

.container {
  width: 500px;
}

.container section {
  padding: 0 30px;
  margin-bottom: 16px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #7e7e7e;
}

.container footer {
  background-color: #fffaeb;
  padding: 16px 30px;
}

.container footer button {
  appearance: none;
  border: 0;
  font-weight: 600;
  font-size: 14px;
}

.button_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

@media screen and (max-width: 700px) {
  .button_container {
    justify-content: center;
  }
}

.button_container button:first-of-type {
  background-color: #fffaeb;
  width: 80px;
  height: 32px;
  margin-right: 10px;
  height: 35px;
  padding: 0;
}

.button_container button:last-of-type {
  background-color: #fffaeb;
  background: #199473;
  border-radius: 3px;
  color: #fff;
  width: 100px;
  height: 35px;
  padding: 0;
}

.button_container button:last-of-type:disabled {
  cursor: not-allowed;
}

.form_container {
  p {
    margin-bottom: 10px;
  }

  textarea {
    width: 100%;
    padding: 5px;
  }
}
