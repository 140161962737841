.error-con {
  text-align: center;
  padding: 100px;
}
.error-con span {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #222222;
}
.error-msg {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
  color: #222222;
}
.error-action {
  text-align: center !important;
  background: #fff3c4;
  border-radius: 3px;
  border: #fff3c4 !important;
  color: #de911d !important;
}
