a {
  text-decoration: none;
}

.header-cont {
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  max-width: 100vw;
  z-index: 990;
  box-shadow: 0px 1px 30px 0px rgba(1, 14, 32, 0.06);
}

.header-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  width: 90vw;
  max-width: 1330px;
  margin: 0 auto;
}

#nav-links {
  width: 130px;
}

.header-logo {
  width: 130px;
}

#nav-links .dropdown {
  position: relative;
  display: inline-block;
}

#nav-links .dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 4px;
  margin: 0;
}

#nav-links .dropdown-menu a {
  color: #455a64;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 0.25rem;
}

#nav-links .dropdown-menu a:hover {
  background: #f0b42921;
}

#nav-links .dropdown:hover .dropdown-menu {
  display: block;
}

#nav-links .dropdown-toggle {
  cursor: pointer;
  background: transparent !important;
  color: #455a64 !important;
}

#nav-links a {
  color: #455a64;
  font-size: 16px;
  text-decoration: none !important;
}
#nav-links a.active,
#nav-links a:hover,
.header-login:hover {
  color: #f0b429;
}

.navlink-cont {
  display: none;
}

.login-cont {
  display: none;
}

.header-login {
  color: #010e20;
  font-size: 16px;
  text-decoration: none !important;
}

.header-logout {
  color: #010e20;
  font-size: 16px;
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

.create-account {
  color: #010e20;
  font-size: 16px;
  border: 1.5px solid #010e20;
  border-radius: 50px;
  width: 173px;
  cursor: pointer;
  padding: 10px;
  background: transparent;
}

.create-account:hover {
  color: #f0b429;
  font-size: 16px;
  border: 1.5px solid #f0b429;
  border-radius: 50px;
  width: 173px;
  cursor: pointer;
  padding: 10px;
  background: transparent;
}

.ham-menu,
.close-menu {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  transition: all 0.3s ease-in-out;
}

.close-menu {
  position: absolute;
  top: 25px;
  right: 25px;
  opacity: 0;
}

.close-menu.active {
  opacity: 1;
}

.ham-menu img,
.close-menu img {
  height: 31px;
  margin: 0;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background-color: #fff;

  overflow-y: scroll;
  transition: left 0.5s ease;
  z-index: 1002;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mobile-menu.open {
  left: 0;
}

.mob-nav {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
  padding: 0 10px;
}

.mob-nav a {
  color: #455a63;
  font-size: 20px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}

.mob__logout {
  border: none;
  outline: none;
  background: transparent;
  color: #455a63;
  font-size: 20px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.mob-nav a.active {
  color: #de911d;
  font-weight: 500;
}

.mob-create-account {
  color: #455a63;
  font-size: 20px;
  border: 1.5px solid #455a63;
  border-radius: 50px;
  width: 65%;
  min-width: 222px;
  padding: 10px;
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition: all 0.5s ease;
  display: none;
  z-index: -999;
  height: 100%;
}

.overlay.active {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  z-index: 999;
  width: 100%;
}

@media screen and (min-width: 700px) {
  .overlay {
    display: inline;
  }
  .mobile-menu {
    left: -45%;
    width: 45%;
  }
}

@media screen and (min-width: 1200px) {
  .overlay {
    display: none;
  }
  .overlay.active {
    display: none;
  }
  .mobile-menu {
    display: none;
  }
  .ham-menu {
    display: none;
  }
  .navlink-cont {
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 30px;
  }
  .login-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .header-inner {
    padding: 18px 0;
  }
}
