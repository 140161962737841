.left_container {
  padding: 16px;
  background: #fff;
  font-family: 'Mulish', sans-serif;
  overflow-y: scroll;
  padding-bottom: 30px;

  label {
    margin: 0;
    margin-right: 8px;
    font-family: 'Mulish', sans-serif;
    font-size: 13px;
    font-weight: 300;
    color: #000000;
    text-transform: capitalize;
  }
}

.organizations {
  border: 1px solid #e1e1e1;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.organizations_toggle_button {
  appearance: none;
  border: none;
  background-color: #fff;
  
  padding: 0;
  text-align: left;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;

  &:hover {
    text-decoration: underline;
  }
}

.organizations_config_wrapper {
  display: grid;
  gap: 10px;
  margin-bottom: 15px;
}

.change_button {
  padding: 0;
  border: 1px solid;
  font-family: 'Mulish', sans-serif;
  font-size: 16px !important;
  text-align: center;
  position: relative;
  padding: 5px 0 !important;
  appearance: none;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.variables {
  & > p {
    font-size: 14px;
  }
}

.no_variables {
  font-size: 12px;
}
