.wrapper {
  background: #fff;
  box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 48px;
  .inputs:focus {
    border: 1px #cfcfcf solid !important;
    background: #f7f7f7 !important;
  }
  .text {
    width: 50%;
  }
  .row {
    margin-top: 50px;
  }
  .dropdown-input {
    width: 100%;
  }
  .button_container {
    padding-top: 88px;
    text-align: center;
  }
  .add_button {
    background: #4caf50;
    :hover {
      background-color: #4caf50;
    }
  }
}

.template_header {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  font-family: 'Mulish', sans-serif;
  margin-bottom: 0;
}

.add_template_btn {
  width: 165px;
  height: 48px;
  background: #e6e6e6;
  border-radius: 3px;
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #222222;
  border: none;
}

.add_template_btn:hover {
  opacity: 0.8;
}

.form_container {
  margin: 0 auto;
  width: 95%;
  max-width: 970px;
  margin-top: 70px;
}

.label {
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #626262;
}

.text_field {
  background: #fff;
  width: 100%;
  height: 51px;
}

.text_field:focus {
  outline: none !important;
  box-shadow: none;
}

.inputs_container {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.organization_container {
  width: 100%;
}

.button_container {
  margin-top: 40px;
  display: flex;
  gap: 22px;
  justify-content: center;
}

.create_template_btn {
  max-width: 304px;
  width: 100%;
  height: 48px;
  left: 954px;
  top: 563px;
  color: #fff;
  background: #4caf50;
  border-radius: 3px;
}

.create_template_btn:active,
.create_template_btn:hover {
  color: #fff;
  opacity: 0.9;
}

.create_template_btn:disabled {
  cursor: not-allowed;
}
