.revisionData {
  text-align: center;
  margin: auto;
}
.MuiDialog-paper {
  display: grid;
  grid-template-columns: 1fr;
  /* width:90vw!important;  please don't enable this*/
}
.review-container {
  display: grid;
  grid-template-columns: 1fr;
  width:30vw;
}
.review-cards {
  /* box-shadow: 5px 10px 18px #888888; */
  border: 1px rgb(231, 231, 231) solid;
  width: 90% !important;
  margin: 25px auto 0 auto;
  display:grid;
  grid-template-columns:1fr 2fr;
  padding:20px 10px;
}
.revision-info{
  text-align:left;
  padding-left:15px;
}
.revision-modal-name{
  font-size:20px;
  font-weight:bold;
  text-align:left;
}
.revision-time-stamp{
  padding:0!important;
  margin:0!important;
}
.revision-modal-header{
  font-weight:bold;
  text-align:left;
  padding:20px 0 0 20px;
  font-size:25px;
  color:#fcaf3b;
  text-transform:uppercase;
}
.cardMedia {
  width: 100px !important;
  height: 100px;
  border-radius: 100px;
  margin: 0 auto;
}
