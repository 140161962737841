.panel {
  width: 80%;
  margin: 2em auto;
}

.panel-body {
  width: 90%;
  margin: 2em auto;
}

body {
  font-size: 15px;
}

label {
  font-weight: 400;
}

.helper-text {
  color: #e91e63;
  font-size: 12px;
  margin-top: 5px;
  height: 12px;
  display: block;
}

.helper-text-green {
  color: green;
}

.toggle-button {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
  cursor: pointer;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.4s;
}

.inputBox[type="checkbox"] {
  display: none;
}

.inputBox[type="checkbox"]:checked + .toggle-slider {
  background-color: #de911d;
}

.inputBox[type="checkbox"]:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.cardControls {
  border-top: 0.5px solid #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2%;
}

.mainCardCont {
  overflow-y: auto;
  height: 70vh;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardCont {
  box-shadow: 5px 5px 20px rgba(133, 133, 133, 0.1);
  padding: 3%;
  border-radius: 10px;
  margin: 3% 0;
  width: 80%;
}

/* Hosted Payment Fields styles*/
.hosted-field-focus {
  outline: none;
  background-image: linear-gradient(#009688, #009688),
    linear-gradient(#d2d2d2, #d2d2d2);
  animation: input-highlight 0.5s forwards;
  box-shadow: none;
  background-size: 0 2px, 100% 1px;
  transition: 0.5s ease-in-out all;
}

.hosted-textfield {
  cursor: text;
  font: medium -moz-fixed;
  /*font: -webkit-small-control;*/
  height: 60px;
  overflow: auto;
  padding: 2px;
  border-radius: 4px;
  border: 1px #ccc solid;
  transition: 0.5s ease-in-out all;
  padding-left: 2%;
  /* -moz-box-shadow: inset 0px 1px 2px #ccc;
  -webkit-box-shadow: inset 0px 1px 2px #ccc;
  box-shadow: inset 0px 1px 2px #ccc; */
}

.card-name {
  margin-bottom: 20px;
}
.card-number {
  display: grid;
  grid-template-columns: 10fr 1fr;
  margin-bottom: 20px;
}
.div-inner {
  width: 98%;
}
.card-extras {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
}
.card-logo {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .cardControls {
    flex-direction: column;
  }
  .make_default {
    padding: 3% 0;
    background-color: #fffaeb;
    width: 100%;
    justify-content: center;
  }
  .delete_card {
    padding: 3% 0;
    background-color: #fffafa;
    width: 100%;
    justify-content: center;
    margin-top: 3%;
  }
}
