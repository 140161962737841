.confirm__email .background{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:30vh;
    background:#F7C948;
    z-index:-100;
}
.confirm__email .container{
    background:#fff;
    box-shadow:0px 3px 6px #00000029;
    width:40vw;
    margin:10vh auto;
    padding:7.5vh 7.5vw;
    border-radius:5px;
    z-index:10;
}

.confirm__email .email_icon{
    width:7.5vw;
    margin-bottom:2vh;
}
.confirm__email h1{
    margin-top:3vh;
}
.confirm__email .logo{
    margin-top:3vh;
}