.nav-row:hover{
    background-color: #E1E1E1;
}
.app-builder-parent{
    padding:0!important;
    .app-builder{
        padding:0!important;
        overflow-y: auto;
        overflow-x: hidden;
        .item-head{
            padding:30px 20px;
            display:flex!important;
            justify-content:space-between!important;
            h5{
                margin:0;
                padding:0;
                line-height:0;
            }
            img{
                width:20px!important;
                margin-right:10px;
            }
        }
        .item-head-2{
            padding:0 35px;
            display:flex!important;
            justify-content:space-between!important;
            h5{
                margin:0;
                padding:0;
            }
            img{
                width:20px!important;
                margin-right:10px;
            }
        }
        .items-to-add{
            .item{
                padding:30px 20px;
                transition:.5s ease-in-out all;
                cursor:pointer;
                &:hover{
                    background:#e1e1e1;
                    transition:.5s ease-in-out all;
                }
                p{
                    line-height:0px!important;
                    margin:0;
                }
                .makeStyles-navTitle-47{
                    line-height:0px!important;
                    margin:0;
                }
                .makeStyles-navTitle-31{
                    line-height:0px!important;
                    margin:0;
                }
            }
        }
        .draggable-sections-container{
            padding:0 35px;
        }
    }
}

/* .makeStyles-content-4{
    padding:0!important;
} */
