.confirm-modal-container {
  z-index: 10;
}
.modal__component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(7, 7, 7, 0.76);
  z-index: 10;
}
.modal__component .modal__content {
  position: absolute;
  justify-content: center;
  height: 100%;
  color: #fff;
  margin-top: 0;
  z-index: 10;
}
.modal__component .modal__content .close__modal {
  margin: 10px 0;
  cursor: pointer;
}
.modal__component .modal__content .modal__title {
  margin: 10px 0;
  color: #fcfcfc;
}
.modal__component .modal__content .modal__card {
  background: #fff;
  width: 20vw;
  border-radius: 3px;
  padding: 20px 20px;
  margin-top: 50px;
  color: #3b3b3b;
  position: absolute;
  bottom: 25vh;
  margin: 0 auto;
  left: 40vw;
  text-align: center;
  font-weight: bold;
  z-index: 10;
}
.modal__component .modal__content .modal__card P {
  margin-top: 10px;
}
.modal__component .modal__content .modal__card > div {
  margin-bottom: 20px;
}
.modal__component .modal__content .modal__card form > div {
  margin-bottom: 20px;
}
.modal__component .modal__content .modal__card .confirm__header {
  color: #03293d;
  text-align: center;
  font-size: 20px;
  margin: 40px 0;
}
.modal__component .modal__content .modal__card button {
  margin-top: 20px;
  background: #2871b6;
  color: #fff;
  border: none;
  width: 100%;
  padding: 10px 30px;
  border-radius: 4px;
}
.modal__component .modal__content .modal__card .cancel {
  color: #ef4e4e;
  margin-top: 30px;
  cursor: pointer;
}

.form-control.input-area-gd {
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .dbCredential_title {
    font-size: 18px !important;
  }
  .dbCredential_label {
    font-size: 16px !important;
  }
}
