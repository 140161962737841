.app-form-container{
    background:#FFFAEB;
    position:fixed;
    width:100vw;
    height:100vh;
    top:0;
    left:0;
    overflow-y:scroll;
    padding-bottom:200px;
    .background-image{
        position:fixed;
        top:0;
        left:0;
        width:100%;
        height:200px;
        background-color:#F0B429;
    }
    .app-form-content{
        background:#fff;
        width:625px;
        position:relative;
        top:100px;
        margin:auto;
        border-radius:12px;
        padding:30px 40px 113px 40px;
        font-size:16px;
        .head{
            text-align:center;
            border-bottom: 1px solid #CFCFCF;
            padding-bottom:20px;
            .name{
                font-weight:bold;
                font-size:24px;
                margin-bottom:11px;
            }
        }
        input{
            border:1px solid #F7F7F7;
            background:#F7F7F7;
            border-radius:5px;
        }
    }
}