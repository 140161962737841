.sync__btn {
  color: #fff;
  background: #f7c948;
  border-radius: 6px;
  border: none;
  padding: 10px 30px;
  margin-bottom: 7%;
}

.sync__btn2 {
  color: #fff;
  background: #f7c948;
  border-radius: 6px;
  border: none;
  padding: 15px 20px;
}
