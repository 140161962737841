.swal-footer{
    background:#fffaeb;
    margin-top:40px;
}
.swal-title{
    text-align:left;
    padding:40px 45px 0 45px;
    margin:0;
}
.swal-text{
    padding:0 35px;
    margin:0;
}

.action-button-tab{
    padding:8px 17px;
    transition:.5s ease-in-out all;
    background:#fff;
    font-size:12px;
}
.action-button-tab:hover{
    transition:.5s ease-in-out all;
    background:#e1e1e1;
    cursor:pointer;
}
.action-button-tab img{
    width:10px;
    margin-right:12px;
}


.style-button{
    padding:12px 32px;
    border:none;
    border-radius:3px;
    outline:none;
}
.style-button-two{
    padding:2px 14px;
    border:none;
    border-radius:3px;
    outline:none;
}

.full-button{
    width:100%;
}

.bg-gold-button{
    background:#F7C948;
    border: 1px solid #F7C948;
    transition:.5s ease-in-out all;
    color:#fff;
    cursor:pointer;
    &:hover{
        border: 1px solid #F7C948;
        transition:.5s ease-in-out all;
        background:#fff;
        color:#F7C948;
    }
}

.text-input{
    margin-bottom:25px;
    text-align:left;
    /* width:100%; */
    .required{
        color:red;
    }
}
.text-input .label{
    margin-bottom:8px;
    color:#607080;
    display:flex;
    justify-content:space-between;
}
.text-input input{
    box-sizing: border-box;
    border-radius: 10px;
    padding:14px 20px;
    width:100%;
    outline:none;
    border:none;
    &::placeholder{
        color:rgb(189, 189, 189);
        font-weight:bolder;
    }
}
.text-input textarea{
    box-sizing: border-box;
    border-radius: 10px;
    padding:23px 20px;
    width:100%;
    outline:none;
    border:none;
    height:115px;
    &::placeholder{
        color:rgb(189, 189, 189);
        font-weight:bolder;
    }
}

.txt-input2{
    padding:10px 18px!important;
}