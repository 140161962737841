.progress_container {
  height: 4px;
  width: 100%;
  background-color: #fff;
}

.container {
  border-bottom: 1px solid #e1e1e1;
  height: 81px;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  max-width: 1800px;
  margin: 0 auto;
}

.container header {
  height: calc(100% - 4px);
  margin: 0 auto;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

@media screen and (max-width: 900px) {
  .container header {
    width: 95%;
  }
}

.container header > a:first-of-type {
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #333333;
  text-decoration: none;
}

.container header > a:first-of-type img {
  margin-right: 16px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu_icon {
  margin-left: 28px;
  display: flex;
  align-items: center;
}

.user__image__container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #c4c4c4;
  margin-right: 10px;
}

.nav_links {
  list-style-type: none;
  margin-left: auto;
  margin-bottom: 0;
  display: flex;
}

.nav_links li a {
  color: #000;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
}

.print_stats_container li {
  line-height: 17.16px;
  color: #000;
  font-size: 12px;
  font-family: 'Mulish';
  font-style: normal;
}
