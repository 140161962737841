.hero-section {
  background-color: white;
  padding: 70px 0 30px;
}

.hero-section a,
.main-section a,
.step-section a {
  text-decoration: none;
}
.hero-section-inner {
  width: 90vw;
  max-width: 1330px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}

.hero-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-text h1 {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: #010e20;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 7px;
  flex-wrap: wrap;
}

.hero-text h1 .hero-title {
  font-family: "Rubik", sans-serif;

  font-weight: 700;
  color: #010e20;
}

.hero-span {
  display: none;
}
.hero-span2 {
  display: inline-flex;
}
.hero-span,
.hero-span2 {
  background-color: #fff4dc;
  font-family: "Rubik", sans-serif;
  font-weight: 700;

  color: #010e20;
  padding: 0 6px 0 4px;

  width: 243px;
  height: 42px;
  overflow: hidden;
  border-left: 2px solid #f0b429;
}

.inner-span {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  /* width: 150px !important; */
}

.hero-p {
  font-size: 16px;
  line-height: 24px;
  color: #010e20a6;
  text-align: center;
  margin: 15px 0;
}

.hero-button {
  background-color: #f0b429;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border: none;
  outline: none;
  margin: none;
  width: 60%;
  min-width: 211px;
  max-width: 233px;
  text-decoration: none !important;
}

.hero-button:hover {
  opacity: 0.8;
}

.hero-button p {
  font-size: 15px;
  line-height: 24px;
  color: #010e20;
  margin: 0;
  font-family: "Rubik", sans-serif;
}

.hero-button img {
  width: 24px;
  height: 24px;
}

.hero-img-section {
  width: 100%;
  position: relative;
  max-width: 400px;
  margin: 80px auto 0;
}

.hero-img {
  width: 100%;
}

.hero-card1,
.hero-card2 {
  background-color: white;
  border: 0.5px solid #e9eaf2;
  border-radius: 9px;
  padding: 20px;
  width: 224px;
  box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.07);
}

.hero-card1 {
  position: absolute;

  top: -66px;
  right: 0;
}
.hero-card2 {
  position: absolute;

  bottom: -66px;
  left: 0;
}

.startups {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  width: 90vw;
  max-width: 1330px;
  margin: 120px auto 0;
}

.startups h2 {
  color: #010e20;
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  text-align: center;
}

.startup-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.startup-logos img {
  width: 85px;
}

.main-section,
.carousel-section {
  background-color: #fcfcfc;
}

.step-section {
  background-color: #fff;
}

.main-section-inner,
.step-section-inner,
.carousel-section-inner {
  width: 90vw;
  max-width: 1330px;
  margin: 0 auto;
}

@media screen and (min-width: 700px) {
  .hero-section-inner {
    flex-direction: row;
    gap: 2%;
  }
  .hero-text h1 {
    justify-content: flex-start;
  }
  .hero-text {
    width: 75%;
    align-items: flex-start;
  }
  .hero-text h1,
  .hero-text p {
    text-align: left;
  }
  .hero-img-section {
    width: 49%;
    max-width: none;
    margin: 50px auto 0;
    display: flex;
    justify-content: center;
  }
  .hero-card1 {
    top: -30px;
  }
  .hero-card2 {
    bottom: -30px;
  }
  .hero-button {
    width: 205px;
    min-width: 205px;
  }
  .startups h2 {
    font-size: 18px;
    line-height: 27px;
    width: 70%;
  }
  .startup-logos img {
    width: 99px;
  }
  .startup-logos {
    gap: 22px;
  }
}
@media screen and (min-width: 768px) {
  .hero-text h1,
  .hero-span,
  .hero-span2 {
    font-size: 33px;
    line-height: 47px;
  }
  .hero-text h1 {
    gap: 2px 8px;
  }
  .hero-span,
  .hero-span2 {
    height: 47px;
    width: 252px;
  }
  .hero-p {
    font-size: 17px;
    line-height: 25px;
    margin: 20px 0;
  }
  .startups h2 {
    width: 65%;
  }
}
@media screen and (min-width: 1000px) {
  .hero-img {
    width: 85%;
  }
  .hero-span2 {
    display: none;
  }
  .hero-span {
    display: inline-flex;
  }
  .hero-text h1,
  .hero-span,
  .hero-span2 {
    font-size: 40px;
    line-height: 53px;
  }
  .hero-text h1 {
    gap: 3px 10px;
  }
  .hero-span,
  .hero-span2 {
    height: 53px;
    width: 303px;
  }
  .hero-p {
    font-size: 18px;
    line-height: 26px;
    margin: 20px 0;
  }
}
@media screen and (min-width: 1200px) {
  .hero-card1 {
    top: -10px;
    right: 0%;
  }
  .hero-card2 {
    bottom: -10px;
  }
  .hero-img {
    width: 80%;
  }
  .hero-text h1 {
    gap: 3px 12px;
  }
  .hero-text h1,
  .hero-span,
  .hero-span2 {
    font-size: 48px;
    line-height: 57px;
  }
  .hero-span,
  .hero-span2 {
    height: 57px;
    width: 363px;
  }
  .hero-p {
    font-size: 20px;
    line-height: 28px;
    margin: 25px 0;
  }
  .hero-card1,
  .hero-card2 {
    width: 40.5%;
  }
  .hero-section {
    padding-bottom: 60px;
  }
  .startups {
    flex-direction: row;
    gap: 2%;
    justify-content: flex-start;
  }
  .startups h2 {
    width: 40%;
    text-align: left;
    font-size: 17px;
    line-height: 25px;
  }
  .startup-logos {
    width: 58%;
    justify-content: flex-start;
  }
}
