.integration-hero-section {
  background-color: white;
  padding-top: 30px;
}
.info-cont {
  padding-top: 35px;
}
.info-cont-inner {
  width: 90vw;
  max-width: 1330px;
  margin: 0 auto;
}
.integration-hero-section-inner {
  width: 90vw;
  max-width: 1330px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}

.integration-hero-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.integration-hero-text h1 {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: #010e20;
}

.integration-hero-span {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: #f0b429;
}

.integration-hero-p {
  font-size: 16px;
  line-height: 24px;
  color: #010e20a6;
  text-align: center;
  margin: 15px 0;
}
.integration-hero-img-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.integration-hero-img-section img {
  width: 100%;
  max-width: 400px;
}

@media screen and (min-width: 700px) {
  .integration-hero-section-inner {
    flex-direction: row;
    gap: 2%;
  }
  .integration-hero-text {
    width: 49%;
    align-items: flex-start;
  }
  .integration-hero-text h1,
  .integration-hero-text p {
    text-align: left;
  }
  .integration-hero-img-section {
    width: 49%;
  }
  .integration-hero-img-section img {
    max-width: max-content;
  }
  .info-cont {
    padding-top: 60px;
  }
}
@media screen and (min-width: 768px) {
  .integration-hero-text h1,
  .integration-hero-span {
    font-size: 34px;
    line-height: 47px;
  }
  .integration-hero-p {
    font-size: 17px;
    line-height: 25px;
    margin: 20px 0;
  }
}
@media screen and (min-width: 1000px) {
  .integration-hero-img {
    width: 85%;
  }
  .integration-hero-text h1,
  .integration-hero-span {
    font-size: 40px;
    line-height: 53px;
  }
  .integration-hero-p {
    font-size: 18px;
    line-height: 26px;
    margin: 20px 0;
  }
}
@media screen and (min-width: 1200px) {
  .integration-hero-text h1,
  .integration-hero-span {
    font-size: 48px;
    line-height: 57px;
  }
  .integration-hero-p {
    font-size: 20px;
    line-height: 28px;
    margin: 25px 0;
  }
  .integration-hero-card1,
  .integration-hero-card2 {
    width: 40.5%;
  }
}
