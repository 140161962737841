.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.actionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  p {
    margin-bottom: 0;
  }

  .manageButton {
    appearance: none;
    padding: 5px;
    border: 0;
    background-color: #fff;
    color: #0277bd;
    font-size: 14px;
    font-weight: 500;

    &:disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.signeeActionsWrapper {
  margin-bottom: 4px;
}

.blueButton {
  background-color: rgb(2, 119, 189);
  color: #fff;
  padding: 10px 0 !important;

  &:hover {
    color: #fff;
    background-color: rgba(2, 119, 189, 0.9);
  }

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
    pointer-events: none;
  }
}

.blueButton_outline {
  color: rgb(2, 119, 189);
  background-color: #fff;
  padding: 10px 0 !important;
  border: 1px solid rgb(2, 119, 189);

  &:hover {
    color: #fff;
    background-color: rgba(2, 119, 189, 0.9);
  }

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
    pointer-events: none;
  }
}

.redButton_outline {
  color: rgb(254, 0, 0);
  background-color: #fff;
  padding: 10px 0 !important;
  border: 1px solid rgb(254, 0, 0);

  &:hover {
    color: #fff;
    background-color: rgba(254, 0, 0, 0.9);
  }

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
    pointer-events: none;
  }
}

.formLabel {
  color: #626262;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.buttons_container {
  margin: 0 auto;

  & > button {
    border: 1px solid;
    width: 100%;
    font-family: 'Mulish', sans-serif;
    font-size: 16px !important;
    text-align: center;
    position: relative;
    padding: 10px 0 !important;
    margin: 0;
    appearance: none;
    border-radius: 4px;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    i.absolute {
      font-size: 12px;
      position: absolute;
      right: 15%;
      top: 53%;
      transform: translateY(-50%);
    }

    &:disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.field {
  width: 100%;
  text-align: left;
  font-size: 14px;
  padding: 12px 12px 12px 11px;
  background-color: #fafafa;
  border: 0;
  border-left: 4px solid #0277bd;
  display: block;
}

.labelTag {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #000;
}
