.feature-cont {
  padding-top: 35px;
  padding-bottom: 35px;
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  flex-direction: column;
}

.text-cont h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  color: #010e20;
  text-align: center;
}

.text-cont h2 span {
  color: #f0b429;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
}

.text-cont p {
  color: #010e20a6;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 15px;
}

.features-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px 0;
  margin-top: 20px;
}

.feature {
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.05);
  background-color: white;
  width: 100%;
  padding: 25px;
  border-radius: 10px;
}

.feature img {
  width: 42px;
}

.feature h2 {
  color: #010e20;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  margin: 16px 0;
}

.feature p {
  color: #010e20a6;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (min-width: 700px) {
  .feature-cont {
    padding: 60px 0;
    /* margin-top: 20px; */
  }
  .text-cont h2 {
    font-size: 30px;
    line-height: 37px;
  }
  .text-cont p {
    font-size: 18px;
    line-height: 26px;
  }

  .feature {
    width: 48.6%;
  }
}

@media screen and (min-width: 1200px) {
  .feature-cont {
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
    padding: 80px 0 60px;
  }
  .text-cont {
    width: 40%;
  }
  .features-card {
    width: 58%;
    margin-top: 0;
  }
  .feature{
    padding: 30px;
  }
  .text-cont h2 {
    font-size: 36px;
    line-height: 43px;
    text-align: left;
  }
  .text-cont p {
    font-size: 16px;
    line-height: 26px;
    text-align: left;
  }
}
