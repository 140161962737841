.templates_table_container {
  .small {
    color: #515151;
  }
  table {
    margin-top: 0;

    th {
      font-family: 'Mulish', sans-serif;
      font-weight: bold;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #515151;
      border: none;
      border-bottom: 2px solid #cfcfcf;
      padding: 24px 16px;
      padding-left: 20px;
    }

    th:first-of-type {
      padding-left: 30px;
    }

    td {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      color: #000 !important;
      background-color: #fff;
      border-left: none !important;
      border-right: none !important;

      a,
      h3 {
        color: #000 !important;
        font-size: 16px !important;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #515151;
        margin-bottom: 0;
      }
    }
    tbody {
      tr {
        td:first-of-type {
          padding-left: 30px;
        }
      }
    }
  }
}

.actions_container div {
  background-color: #fff;
  width: 30px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  padding: 10px;
  margin: auto;
}

.menu_item {
  font-size: 16px;
  color: #626262;
  margin-bottom: 0 !important;
}
