.faq-cont {
  padding-top: 35px;
  padding-bottom: 70px;
}

.faq-text-cont {
  max-width: 797px;
  margin: 0 auto;
}

.faq-text-cont h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  color: #010e20;
  text-align: center;
}

.faq-text-cont p {
  color: #010e20a6;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 15px;
}

.faq-questions-cont {
  width: 100%;
  max-width: 955px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  margin-top: 30px;
}

.faq-item {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.05);
}

.faq-question {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.faq-question h2 {
  font-size: 15px;
  line-height: 17.7px;
  font-weight: 500;
  color: #010e20;
  font-family: "Rubik", sans-serif;
}

.faq-question img {
  width: 20px;
}

.faq-answer {
  display: grid;
  overflow: hidden;
  height: fit-content;
  transition: all 0.4s ease-in-out;
}

.faq-open {
  grid-template-rows: 1fr;
  opacity: 1;
}
.faq-close {
  grid-template-rows: 0fr;
  opacity: 0;
}

.faq-button {
  background-color: #f0b429;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border: none;
  outline: none;
  margin: 30px auto 0;
  width: 60%;
  min-width: 211px;
  max-width: 233px;
  text-decoration: none !important;
}

.faq-button p {
  font-size: 15px;
  line-height: 24px;
  color: #010e20;
  margin: 0;
  font-family: "Rubik", sans-serif;
}

.faq-button img {
  width: 24px;
  height: 24px;
}

.overflow-hidden {
  overflow: hidden;
}

.faq-answer hr {
  margin-top: 20px;

  height: 1px;
  width: 100%;
  background-color: #c2c2c259;
  border: none;
  outline: none;
}

.faq-answer p {
  color: #010e20a6;
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;
}

@media screen and (min-width: 700px) {
  .faq-cont {
    padding: 60px 0 110px;
  }
  .faq-text-cont h2 {
    font-size: 30px;
    line-height: 37px;
  }
  .faq-text-cont p {
    font-size: 18px;
    line-height: 26px;
  }
  .faq-item {
    padding: 24px;
  }


}

@media screen and (min-width: 1200px) {
  .faq-cont {
    padding: 80px 0 140px;
  }
  .faq-questions-cont {
    margin-top: 40px;
  }

  .faq-text-cont h2 {
    font-size: 36px;
    line-height: 43px;
  }
  .faq-text-cont p {
    font-size: 16px;
    line-height: 26px;
  }

}
