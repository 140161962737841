.file__haven .protect__all {
  color: #fff;
  border-radius: 5px;
  background: #f7c948;
  border: none;
  padding: 10px 30px;
}

.file__haven h3,
.file__haven h4 {
  font-size: 20px;
}

.file__haven .file__haven__btn {
  color: #fff;
  background: #f7c948;
  border-radius: 6px;
  border: none;
  padding: 15px 30px;
}

.file__haven .file__haven__btn:disabled {
  cursor: not-allowed;
  opacity: 0.9;
  font-size: 14px;
}

.file__haven_empty_container {
  margin-top: -90px;
}

@media only screen and (max-width: 768px) {
  .fileHaven_title {
    font-size: 16px !important;
  }
  .file__haven__btn {
    font-size: 0.8rem !important;
    padding: 15px 20px !important;
  }
  .file_haven_org{
    flex-direction: column;
  }
  .file_haven_btn{
    margin-top: -8%;
    margin-bottom: 7%;
  }
  .haven_modal_header{
    width: 300px !important;
  }
}
