.integrations{
    position:relative;
    overflow-y:hidden;
    .link22{
        text-decoration:none;
        cursor:pointer;
    }
    .link222{
        color:#515151;
    }
    .landing{
        padding:135px 25vw 52px 25vw;
        img{
            width:100%;
        }
        .font-48{
            margin:55px 0 24px 0;
        }
        .color-black-2{
            margin-bottom:48px;
        }
    }
    .features{
        padding:58px 25vw 146px 25vw;
        .head{
            margin-bottom:75px;
            text-align:center;
            .font-32{
                margin-bottom:24px;
            }
            .subtitle{
                padding:0 300px;
            }
            .integrations-navigation{
                padding-top:20px;
                text-align:center;
                span{
                    padding-bottom:10px;
                    margin-right:80px;
                    transition:.4s ease-in-out all;
                    cursor:pointer;
                    &:hover{
                        color:#000;
                        transition:.4s ease-in-out all;
                    }
                    &:last-child{
                        margin:0;
                    }
                }
                .active{
                    color:#000;
                    border-bottom:3px solid #0e365a;
                }
            }
        }
        .quadruple-container{
            .item{
                padding:12px 21px 12px 16px;
                background: #FFFFFF;
                border: 1px solid #E1E1E1;
                box-sizing: border-box;
                border-radius: 3px;
                margin-bottom:32px;
                display:flex;
                align-items:center;
                transition:.25s ease-in-out all;
                .client{
                    width:37px;
                }
                .text{
                    display:inline-block;
                    margin-left:16px;
                    width:100%;
                    .name{
                        display:flex;
                        justify-content:space-between;
                        align-items:center;
                        width:100%!important;
                        margin-bottom:8px;
                        img{
                            height:10.5px;
                            opacity:0;
                            transition:.25s ease-in-out all;
                        }
                    }
                    .tag{
                        padding:2px 8px;
                        border-radius:4px;
                    }
                }
                &:hover{
                    transition:.25s ease-in-out all;
                    border: 1px solid #FFF3C4;
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
                    .text{
                        img{
                            opacity:1;
                            transition:.25s ease-in-out all;
                        }
                    }
                }
            }
        }
        .button-container{
            text-align:center;
        }
    }
  
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
  
    /* start laptop version */
    @media screen and (max-width:2450px){
        .landing{
            padding:135px 0 52px 88px;
        }
        .features{
            .head{
                .subtitle{
                    padding:0 250px;
                }
            }
            padding:58px 204px 146px 204px;
        }
    }
  
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
  
    /* start tablet version */
    @media screen and (max-width:1024px){
        .landing{
            padding:135px 0 52px 40px;
        }
        .features{
            .head{
                .subtitle{
                    padding:0 100px;
                }
            }
            padding:58px 100px 146px 100px;
        }
    }
  
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
  
    /* start mobile version */
    @media screen and (max-width:600px){
        .landing{
            padding:50px 20px 52px 20px;
            img{
                width:100%;
            }
            .color-black-2{
                margin-bottom:10px;
            }
        }
        .features{
            padding:25px 20px;
            .head{
                margin-bottom:75px;
                text-align:center;
                .font-32{
                    margin-bottom:24px;
                }
                .subtitle{
                    padding:0 20px;
                }
            }
        }
    }
}