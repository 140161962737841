.nodec {
    cursor: pointer;
}

.nodec circle {
    fill: #fff;
}

.nodec text {
    font: 12px sans-serif;
}

.linkc {
    fill: none;
    stroke: #f2f2f2;
    stroke-width: 2px;
}

.node circle {
    fill: #fff;
    stroke: steelblue;
    stroke-width: 3px;
}

.node text {
    font: 12px sans-serif;
}

.link {
    fill: none;
    stroke: #ccc;
    stroke-width: 2px;
}
