@media screen and (max-width: 768px) {
  .form-holder {
    display: flex !important;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 3%;
  }

  .formControlCont {
    margin: 0 !important;
  }
  .hookName {
    font-size: 14px !important;
  }

  .form-select-input{
    margin-top: 2% !important;
  }
  .form-select-text{
    /* margin-top: -1% !important; */
    border: 1px solid #c4c4c4 !important;
    /* height: 40px; */
  }

}
