.features{
    position:relative;
    overflow-y:hidden;
    .landing{
        background-image: url("../Images/footer-bg.png");
        background-size: cover;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items:center;
        padding: 90px 0 100px 0;
        .text{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 55%;
            max-width: 55%;
            .inner {
                padding-left: 50px;
                max-width: 600px;

                .hero-button {
                    cursor: pointer;
                    background-color: #f0b429;
                    border-radius: 50px;
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 13px;
                    border: none;
                    outline: none;
                    margin: none;
                    width: 60%;
                    min-width: 211px;
                    max-width: 233px;
                    text-decoration: none !important;
                }
                
                .hero-button:hover {
                    opacity: 0.8;
                }
                
                .hero-button p {
                    font-size: 15px;
                    line-height: 24px;
                    color: #010e20;
                    margin: 0;
                    font-family: "Rubik", sans-serif;
                }
                
                .hero-button img {
                    width: 24px;
                    height: 24px;
                }
            }
            .logo{
                height:60px;
                display:block;
            }
            .title{
                font-weight: 700;
                font-size: 48px;
                line-height: 57px;
                color: #010E20;
                margin:0 0 24px 0;
            }
            .color-black-2{
                margin-bottom:32px;
            }
            .double-container{
                div{
                    display:flex;
                    align-items:center;
                }
            }
        }
        .image{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 45%;
            max-width: 45%;
            img{
                width:90%;
                border-radius:10px 0 0 10px;
                position:relative!important;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            }
        }
    }
    .automate{
        margin: 0 auto;
        max-width: 1440px;
        padding: 0 20px;
        .head{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-bottom:68px;
            .title{
                margin-bottom:24px;
                font-size: 36px;
                font-weight: 600;
                max-width: 700px;
            }
            .text{
                max-width: 550px;
            }
        }
        .double-container {
            border-radius: 18px;
            margin-bottom: 100px;
            padding: 50px 40px;

            .tabs-feature {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 20px;
                margin-bottom: 40px;
                cursor: pointer;
                
                .tab-feature {
                    padding: 10px 16px;
                    border-radius: 25px;
                }
            }

            .tab-details {
                display: flex;
                flex-wrap: wrap;

                .tab-text, .tab-image {
                    width: 50%;
                    max-width: 50%;

                    img {
                        width: 100%;
                    }
                }

                .tab-text {
                    padding-right: 50px;
                    font-size: 20px;
                }
            }
        }
    }
    .work-easily{
        margin: 0 auto;
        max-width: 1440px;
        padding: 0 20px;
        position:relative;
        margin-bottom: 100px;
        
        .head{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            .title{
                font-size: 36px;
                font-weight: 600;
                max-width: 700px;
                margin-bottom: 24px;
            }
        }
        .triple-container{
            display: flex;
            flex-wrap: nowrap;
            gap: 20px;

            .item{
                background: #FFFFFF;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
                border-radius: 3px;
                padding:30px;
                margin-bottom:24px;
                img{
                    height:50px;
                }
                .other-text{
                    font-size: 18px;
                    font-weight: 600;
                    margin:16px 0 8px 0;
                }
            }

            .item.one, .item.two, .item.three {
                width: 33.33%;
                max-width: 33.33%;
            }

            .item.four, .item.five {
                width: 50%;
                max-width: 50%;
            }
        }
    }
  
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
  
    /* start tablet version */
    @media screen and (max-width:1024px){
        .landing{
            .text, .image {
                width: 50%;
                max-width: 50%;

                .title {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
        }
    }
  
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
  
    /* start mobile version */
    @media screen and (max-width:600px){
        .landing{
            justify-content: center;
            padding: 50px 20px 60px 20px;
            .text{
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                max-width: 100%;
                padding: 0;
                .inner {
                    padding: 0;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }
                .title{
                    font-size: 30px;
                    line-height: 36px;
                }
            }
            .image{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                max-width: 100%;
                padding-top: 60px;
                img{
                    width:90%;
                    border-radius:10px 0 0 10px;
                    position:relative!important;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
                }
            }
        }

        .automate{
            .head{
                .title{
                    font-size: 30px;
                    line-height: 36px;
                }
            }
            .double-container {
                padding: 30px 20px;
                margin-bottom: 50px;
    
                .tabs-feature {
                    gap: 12px;
                }
    
                .tab-details { 
                    gap: 20px;

                    .tab-text, .tab-image {
                        width: 100%;
                        max-width: 100%;
                    }
    
                    .tab-text {
                        padding-right: 0;
                        font-size: 15px;
                    }
                }
            }
        }

        .work-easily{
            margin-bottom: 50px;
            
            .head{
                .title{
                    font-size: 30px;
                    line-height: 36px;
                }
            }
            .triple-container{
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
    
                .item.one, .item.two, .item.three, .item.four, .item.five {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}