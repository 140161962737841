.home_navigation{
    position:absolute;
    width:100%;
    top:26px;
    left:0;
    .inner{
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:0 5vw;
        z-index:1000;
        img{
            margin-right:48px;
        }
        div{
            display:flex;
            align-items:center;
        }
        span{
            position:relative;
            margin-left:30px;
            z-index:100;
        }
        button{
            margin:0 18px 0 0;
        }
    }
  
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
  
    /* start laptop version */
    @media screen and (max-width:2450px){
        .home_inner{
            padding:0 88px;
        }
    }
  
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
  
    /* start tablet version */
    @media screen and (max-width:1024px){
        .home_inner{
            padding:0 40px;
            .menu{
                img{
                    margin:0;
                }
            }
        }
        .menu-container{
            position:fixed;
            width:100vw;
            height:100vh;
            top:0;
            left:-100vw;
            z-index:10;
            transition:.5s ease-in-out all;
            .background{
                background:rgba(0,0,0,.4);
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
            }
            .menu-list{
                position:fixed;
                top:0;
                right:-100vw;
                background:#fff;
                padding:10px 0;
                width:25vw;
                height:100vh;
                transition:.5s ease-in-out all;
                div{
                    padding:20px 30px;
                    text-align:center;
                }
                .close{
                    text-align:right;
                }
            }
        }
        .menu-container-open{
            left:0;
            transition:.5s ease-in-out all;
            .menu-list{
                right:0;
                transition:.5s ease-in-out all;
            }
        }
    }
  
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
    /* break */
  
    /* start mobile version */
    @media screen and (max-width:600px){
        .home_inner{
            padding:0 20px;
            .menu{
                img{
                    margin:0;
                }
            }
        }
        .menu-container{
            .menu-list{
                width:100vw;
            }
        }
    }
}