.connector {
}
/* Connector tabs */
.connector-tabs {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  z-index: 1000;
  position: relative;
}
.connector-tabs h5 {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #222222;
}
.connector .map-data-button {
  float: left;
  text-align: center !important;
  width: 170px;
  /* background: #fff3c4; */
  background: #f7c948;
  border-radius: 5px;
  /* border: #fff3c4 !important; */
  color: #ffffff !important;
  position: absolute;
  left: 84%;
}

/* Configure connections heading */
.config-con-heading {
  border-bottom: 1px solid #e1e1e1;
  padding: 15px;
}
.config-con-body {
  border-bottom: 1px solid #e1e1e1;
  padding: 15px;
}
.config-con-body .single-row:nth-child(2) {
  border-top: 1px solid #e1e1e1;
  padding-top: 10px;
}
.con-icon img {
  width: 10px;
  margin-right: 10px;
}
.MuiAppBar-colorPrimary {
  color: #f7c948 !important;
  background: #fff !important;
  border-bottom: 1px solid #e1e1e1;
  box-shadow: none !important;
}
.PrivateTabIndicator-colorSecondary-22 {
  background-color: #f7c948 !important;
}
/* Source grid */
.source-grid {
  display: flex;
}
