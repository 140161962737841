.header .MuiAppBar-colorPrimary {
  color: #3b3b3b;
  background: #fff;
}

.user__info__wrapper {
  max-width: 150px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header .MuiAppBar-colorPrimary .user__nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.header .MuiAppBar-colorPrimary .user__nav .user {
  display: flex;
  align-items: center;
  color: #000 !important;
}

.header .MuiAppBar-colorPrimary .user__nav .user .user__image__container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #c4c4c4;
  margin-right: 10px;
}

.header .nav__popup {
  position: relative;
  top: 15px;
  width: 15em;
  left: -15px;
}

.iIncrease:hover {
  font-size: 1.5em;
}

.price_rate_indicator {
  font-size: 12px;
}

.menuCont{
  display: none !important;
}
@media screen and (max-width: 768px) {
  .headerCont{
    width: 100% !important;
  }

  .menuCont{
    display: block !important;
    margin-left: 5% !important;
  }

  .user__image__container{
    display: none;
  }

  .headerNameCont{
    display: none;
  }
  .dropIcon{
    display: none;
  }
}
