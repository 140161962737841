.con{
    box-sizing: border-box;
    margin: 30px auto;
}

.colDm{
    width: 60%;
    height: 85vh;
    margin: 30px auto;
    border: 1px solid #ccc;
}
.cdHeader{
    background: #4b89b3;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 8%;
}

.cdHeader h6{
    font-size: 15px;
    margin: 10px;
    color: #fff;
}
.cdHeader button{
    background: #2e6285;
    color: #fff;
    padding: 5px 10px;
    margin-right: 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 80%;
    margin-top: 5px;
}
.cdHeader button:hover{
    background: #ccc;
    color: #2e6285;
}
.from{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-family: 'Lato', sans-serif;
}
.from input{
    border: none;
    border-bottom: 1px solid #ccc;
    width: 30%;
    margin-left: 20px;
}
.from label {
    margin-top: 30px;
}
.from input:focus{
    outline: none;
}
.to{
    display: flex;
    justify-content: center;
    margin-left: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    font-family: 'Lato', sans-serif;
}
.to input{
    border: none;
    border-bottom: 1px solid #ccc;
    width: 80%;
    margin: 5px;
}
.to input:focus{
    outline: none;
}
.to label {
    margin-top: 30px;
}

.sign{
    margin-right: 10px;
    text-align: center;
}
.sign select{
    margin-left: 5px;
    outline: none;
}
.ck {
    width: 80%;
    margin: auto;
}

@media screen and (max-width: 768px) {
    .colDm{
        height: 100%;
        width: 70%;
    }
}
@media screen and (max-width: 500px) {
    .colDm{
        height: 100%;
        width: 70%;
    }
}
@media screen and (max-width: 900px) {
    .colDm{
        height: 100%;
        width: 70%;
    }
}