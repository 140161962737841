.container {
  max-width: 1800px;
  margin: 0 auto;
  margin-top: 81px;
  display: grid;
  grid-template-columns: minmax(230px, 330px) minmax(800px, 1fr) minmax(230px, 330px);
  min-height: calc(100vh - 81px);
  height: calc(100vh - 81px);
  overflow-y: hidden;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}
