.audit__table {
    width: 100%;
    background: #fff;
    min-height: 300px;
    border-radius: 6px;
    margin-top: 30px;
}

.audit__table table th {
    border-top: 0;
    padding: 20px;
    font-weight: 600;
    text-align: center;
    font-size: 15px;
}

.audit__table table tr td  {
    text-align: center;
    border-top: 1px solid #f1f0f4;
}

.audit__table table tr td .dropdown-toggle::after {display: none;}

.audit__table table tr td .dropdown-menu {
    padding: 0;
}

.audit__table table tr td .dropdown-menu span{
    font-size: 14px;
    cursor: pointer;
    padding: 6px 10px;
}

.audit__table table tr td .dropdown-menu span {
    display: flex;
}

.audit__table table tr td .dropdown-menu span img {
    position: relative;
    bottom:1px;
    margin-right: 10px;
}

.audit__table table tr td .title {
    color: #3B3B3B;
    margin-bottom: 0;
}

.audit__table table tr td .num {
    color: #9E9E9E;
    font-size: 12px;
}

.audit__table table tr td .count {
    font-weight: bold !important;
}

.audit__table table tr td .info {
    color: #27AB83;
    background: #EFFCF6;
    border-radius: 12.5px;
    padding: 5px 10px;
    font-size: 12px;
}

.audit__table .table__popup {
    position: relative;
}

.audit__table input[type="checkbox"]{
    width: 20px;
    margin-left: auto;
    margin-right: auto;
}

.audit__table th input[type="checkbox"]{
    position: relative;
    top: 10px;
}

.audit__table td input[type="checkbox"]{
    position: relative;
    bottom: 10px;
}

.audit__table input[type="checkbox"]:focus {
    outline: 0;
    box-shadow: none;
}