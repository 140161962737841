.general_conatiner .left-section {
    flex: 1;
}
.activity_container {
    flex: 1;
    border: 1px solid #f5f5f5;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-top: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.activity_container .heading {
    font-size: 1.4rem;
    text-align: center;
    margin-top: 1.5rem;
}

td,
th {
    /* border: 1px solid #f5f5f5; */
    margin-left: 5px;
    padding: 0.5rem 0.2rem;
}

tr:nth-child(even) {
    /* background-color: #f5f5f5; */
    color: #7e5e5c;
}

table {
    font-size: 0.8rem;
    margin-top: 1rem;
    color: #7e5e5c;
    width: 100%;
}

tr.bottom_row {
    width: 100%;
}

tr.bottom_row td {
    padding: 0.5rem 2rem;
}

.dt {
    border: 1px solid #f5f5f5;
    padding: 1rem;
    border-radius: 0.5rem;
    word-wrap: break-word;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: fit-content;
}

.dt img{
    width: 100% !important;
}

.activity_container .bottom{
    font-size: 0.8rem;
}

.buttons_area{
    margin-left: 8rem;
    transform: translateY(-66px);
    align-items: center;
}
