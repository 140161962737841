.sync__layout .sync__btn {
  color: #fff;
  background: #f7c948;
  border-radius: 6px;
  border: none;
  padding: 15px 30px;
}

.sync__layout .jiggle {
  display: block !important;
  animation-name: jiggly !important;
  animation-duration: 3s !important;
  animation-iteration-count: infinite !important;
  position: relative;
}

@keyframes jiggly {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0);
  }
}

.sync__layout .title {
  font-size: 14px;
}

.sync__layout .overview__card {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  min-height: 190px;
  text-align: center;
  padding: 20px;
}

.sync__layout .overview__card .title {
  font-size: 16px;
  color: #7e7e7e;
}

.sync__layout .overview__card .count {
  font-size: 48px;
  color: #fadb5f;
}

@media screen and (min-width: 600px) and (max-width: 980px) {
  .sync__layout .overview__card .count {
    font-size: 24px;
  }
}

@media screen and (min-width: 980px) and (max-width: 1200px) {
  .sync__layout .overview__card .count {
    font-size: 37px;
  }
}
