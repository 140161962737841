.template_fields_container,
.document_fields_container {
  margin: 20px;

  select {
    font-size: 12px;
  }

  label {
    margin: 0;
    margin-right: 8px;
    font-family: 'Mulish', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    text-transform: capitalize;
  }

  p {
    font-size: 14px;
  }
}

.grid_group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
}

.paperFields {
  margin-bottom: 20px;
  display: grid;
  gap: 16px;

  fieldset {
    display: grid;
    grid-template-columns: auto 150px;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
  }

  & > p {
    margin: 0;
  }
}

input {
  text-indent: 4px;
}

select {
  text-indent: 8px;
  text-transform: capitalize;
}

input,
select {
  appearance: initial;
  width: 100%;
  height: 40px;
  background-color: #f7f7f7;
  border: 0;
  color: #000;
  font-size: 14px;
  font-family: 'Mulish', sans-serif;
  line-height: 20px;

  &:disabled {
    cursor: not-allowed;
  }
}

.dimensionFields {
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin-right: 0 !important;
  }

  span {
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    height: 42px;
    box-shadow: inset 0px 1px 2px rgba(141, 141, 141, 0.05);
    border-radius: 3px;
    padding-left: 6px;
    padding-right: 6px;
    width: 70px;

    p {
      display: inline;
      margin: 0;
      font-family: 'Mulish', sans-serif;
      font-size: 12px;
      color: #3b3b3b;
    }

    input,
    select {
      background-color: #f7f7f7;
      border: 0;
      appearance: initial;
      color: #515151;
      font-size: 12px;
      font-family: 'Mulish', sans-serif;
      padding: 0 !important;
    }
  }
}

.expansion_panel {
  border: 1px solid #e1e1e1;
  border-radius: 0;
  box-shadow: 0;

  & > * .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
}

.expansion_button {
  height: 40px;
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #3b3b3b;
  width: 100% !important;
  appearance: none !important;
  border: 0;
  background: #fff;
  text-align: left;
  padding: 0;
  padding-left: 35px;

  &:hover {
    background: #f7f7f7;
  }
}

.variables {
  margin-top: 10px;

  & > p {
    font-size: 16px;
    margin-bottom: 14px;
    color: #626262;
  }
}

.scale_container {
  display: grid;
  margin-top: 15px;
  grid-template-columns: 0.3fr 7fr;
  width: 100%;
  gap: 10px;
  align-items: center;
}
