.how-filter-works {
  font-size: 16px;
  text-align: justify;
  color: #515151;
  padding: 0 !important;
  margin: 0 !important;
}
.how-filter-works .docs-sidebar {
  position: fixed;
  top: 150px;
  right: 25px;
  /* background:rgba(255,255,255,.8); */
  padding: 20px;
  font-size: 15px;
}
.how-filter-works .docs-sidebar ul ul {
  margin-left: 0px;
  /* margin-top: 12px; */
}
.how-filter-works .docs-sidebar ul ul li {
  list-style-type: none;
  margin-top: 8px;
}
.how-filter-works .main-content {
  padding: 0 5%;
  margin-top: 3%;
  box-shadow: 0px 1px 14px 0px #00000014;
  border-radius: 10px;
  border-bottom: 1px solid;
}
.filter-url {
  font-style: italic;
  padding: 0;
  word-wrap: break-word;
}
.how-filter-works .container {
  margin: 0 !important;
  padding: 0 80px 80px 80px !important;
  max-width: 100vw !important;
}
.how-filter-works .welcome {
  padding: 0 189px 0 0;
}
.how-filter-works section {
  margin-top: 64px;
  text-align: left !important;
}
.how-filter-works section h1 {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: bold;
  color: #000;
}
.how-filter-works section h2 {
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: bold;
  color: #000;
}
.how-filter-works section h3 {
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: bold;
  color: #000;
}
.how-filter-works section h4 {
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: bold;
  color: #000;
}
.how-filter-works section .widget {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: #c4c4c4;
  margin-bottom: 16px;
}
.how-filter-works p {
  /* text-align:justify; */
  margin-bottom: 16px;
}
.how-filter-works section .image {
  background: #c4c4c4;
  min-height: 20px;
  border-radius: 6px;
  margin-top: 8px;
}
.how-filter-works .links-active {
  color: #f0b429;
  transition: 0.5s ease-in-out all;
  font-size: 18px;
}
.how-filter-works .links-active:hover {
  transition: 0.5s ease-in-out all;
}
.how-filter-works .links {
  color: #222;
  text-decoration: none;
  transition: 0.5s ease-in-out all;
}
.how-filter-works .links:hover {
  color: #f0b429;
  transition: 0.5s ease-in-out all;
  text-decoration: underline;
}

.how-filter-works .list {
  counter-reset: list-number;
}

.how-filter-works .list div:before {
  counter-increment: list-number;
  content: counter(list-number);
  margin-right: 10px;
  margin-bottom: 10px;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #e1e1e1;
  border-radius: 50%;
  color: #3b3b3b;
}

@media screen and (max-width: 768px) {
  .content-nav {
    display: none;
  }

  .how-filter-works .container {
    padding: 0 !important;
  }
  .docs-heading{
    margin-top: 0 !important;
  }
  .main-content{
    width: 100vw;
  }
}
