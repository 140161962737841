.container {
  background-color: #fafafa;
  border-left: 4px solid #0277bd;
  display: flex;
  justify-content: space-between;
  padding-left: 11px;
  margin-bottom: 14px;
}

.nameWrapper {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
    margin-left: 5px;
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.drag {
  cursor: pointer;
  padding: 10px 5px;

  img {
    width: 15px;
    height: 25px;
  }
}
