.action-button-tab {
  transition: 0.5s ease-in-out all;
  background: #fff;
  font-size: 12px;
  text-transform: capitalize !important;
}

.action-button-tab:hover {
  transition: 0.5s ease-in-out all;
  cursor: pointer;
  background-color: none;
}

.action-button-tab img {
  width: 10px;
  margin-right: 12px;
}

.btn-danger {
  border-radius: 0.25rem !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  margin-right: 10px !important;
}

.link__text {
  text-decoration: none;
  color: #000;
}

//printDashboardTemplate styles
.printDashboardTemplate_container {
  height: 100vh;
  margin: 0 auto;
  max-width: 1800px;

  .mother-container {
    background: #f7f7f7;
    margin-top: 80px;
  }
  .pad {
    padding-left: 150px;
    padding-right: 150px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-logo {
    background-color: #fff;
    /* padding: 26px 0; */
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    p {
      margin: 0;
      display: inline;
    }
    .logo {
      display: flex;
      align-items: center;
      p {
        font-size: 18px;
        margin-left: 16px;
      }
    }
  }
  .app-user {
    display: flex;
    align-items: center;
  }
  .header-dashboard {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    h1 {
      color: #3b3b3b;
      font-size: 24px;
    }
    .add-template-button {
      background-color: #0277bd;
      padding: 10px 22px;
      i {
        margin-right: 10px;
      }
    }
    .cancel-button {
      background-color: #e6e6e6;
      padding: 14px 28px;
    }
  }
  .table-holder {
    margin-top: 48px;
    h4 {
      font-size: 18px;
      color: #3b3b3b;
      margin-bottom: 16px;
    }
  }
  .user-drop {
    text-align: right;
  }
  .user-drop .profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #c4c4c4;
    margin-right: 20px;
    display: inline-block;
  }
  .user-drop .info {
    margin: 0;
    padding: 0;
    text-align: left;
    display: inline-block;
  }
  .user-drop .arrow {
    text-align: right;
  }
  h5 {
    margin: 0;
    padding: 0;
    font-size: 17px;
  }
  .email {
    margin: 0;
    padding: 0;
  }
  .link__text {
    text-decoration: none;
    color: #000;
  }
}
