
.sidebar-active{
    background:#464646;
    /* background:#fff; */
    /* #3B3B3B */
}

@media screen and (max-width:600px){
    .makeStyles-drawerOpen-11{
        width:100vw!important;
        z-index:100000!important;
        position:fixed;
        top:0;
        left:0;
        height:100vh;
        overflow-x:scroll;
    }
    .makeStyles-drawerClose-12{
        width:0!important;
        z-index:100000!important;
        position:fixed;
        top:0;
        left:-10px!important;
        height:100vh;
    }
}