.choose__service {
  background: #fffaeb;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 30px;
}

.choose__service .header {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.choose__service .welcome__section {
  margin: 20px 0;
  text-align: center;
}

.choose__service .welcome__section .title {
  color: #8d2b0b;
  font-size: 40px;
}

.choose__service .welcome__section .description {
  color: #626262;
}

.choose__service .connectors {
  margin: 30px 0;
}

.choose__service .connectors .wrapper {
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.choose__service .connectors .connector__box {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 10px;
  position: relative;
  min-height: 390px;
  cursor: pointer;
  text-align: center;
}

.choose__service .connectors .connector__box .label.success {
  color: #27ab83;
  background: #effcf6;
  border-radius: 12.5px;
  font-size: 12px;
  padding: 0 5px;
  width: 33%;
}

.choose__service .connectors .connector__box .label.failure {
  color: #e12d39;
  background: #ffe3e3;
  border-radius: 12.5px;
  font-size: 12px;
  padding: 0 5px;
  width: 36%;
}

.choose__service .connectors .connector__box .label.unavailable {
  color: #626262;
  background: #e1e1e1;
  border-radius: 12.5px;
  font-size: 12px;
  padding: 0 5px;
  width: 36%;
}

.choose__service .connectors .connector__box .image__container {
  height: 150px;
}

.choose__service .connectors .connector__box img {
  width: 150px;
  position: relative;
  margin: 30px 0 70px 0;
}

.choose__service .connectors .connector__box button {
  padding: 10px 25px;
  width: 80%;
  margin-top: 40px;
}

.choose__service .logout {
  text-align: right;
}
.choose__service .logout button {
  color: #fff;
  background: #f7c948;
  border-radius: 6px;
  border: none;
  padding: 15px 30px;
}
.choose__service .logout img {
  width: 20px;
  margin-left: 5px;
}
.image_props {
  height: 119px;
  width: 108px !important;
}

@media screen and (max-width: 600px) {
  .choose__service .welcome__section .title {
    font-size: 24px;
  }

  .choose__service .connectors .connector__box {
    border-radius: 10px !important;
    min-height: 330px !important;
  }

  .choose__service .connectors .get_started_btn {
   margin-top: -30px;
  }
}

/* .choose__service .connectors a {
  color: #fff;
  border-radius: 3px;
  padding: 10px 25px;
  margin: 70px 40px 0 40px;
  display: block;
}

.choose__service .connectors a:hover {
  text-decoration: none;
}

.choose__service .connectors .connector__box a.enabled {
  background: #f7c948;
}

.choose__service .connectors .connector__box a.disabled {
  background: #b1b1b1;
} */
