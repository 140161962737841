.header .MuiAppBar-colorPrimary {
  color: #3b3b3b;
  background: #fff;
}

.header .MuiAppBar-colorPrimary .user__nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header .MuiAppBar-colorPrimary .user__nav .user {
  display: flex;
  align-items: center;
  color: #000 !important;
}

.header .MuiAppBar-colorPrimary .user__nav .user .user__image__container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #c4c4c4;
  margin-left: 50px;
}

.header .nav__popup {
  position: relative;
  top: 15px;
  width: 15em;
  left: -15px;
}

.iIncrease:hover {
  font-size: 1.5em;
}
