.plans-account{
  .bg-gold-button{
    background:#F7C948;
    color:#fff;
    width:100%;
  }
  .header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 3vh 0;
    .left{
      font-size:18px;
      img{
        width:36px;
      }
      .info{
        display:inline-table;
        margin:0 0 0 24px;
      }
      .highlighted {
        font-size:16px;
        color: #fcaf3b;
        margin-top:8px;
        cursor: pointer;
      }
    }
    
    .right {
      text-align: right;
    }
  }

  .body{
    margin:120px 0;
    padding-right:423px;
    .small{
      margin-bottom:24px;
      font-size:12px;
      color:#9e9e9e;
    }
    .list{
      div{
        margin-bottom:6px;
      }
    }
  }

 
} 

  .plans-account-modal{
    padding:0 0 50px 0;

    .header{
      font-size:24px;
      display:flex;
      justify-content:space-between;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      background: #FFFFFF;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
      padding:20px 67px;
      align-items:center;
      z-index:100;

      @media screen and (max-width: 750px) {
        padding: 10px 17px;
      }

      .modal-navigation{
        font-size:14px;
        color:#7E7E7E;
        span{
          margin-right:57px;
          transition:.4s ease-in-out all;
          cursor:pointer;
          padding:10px 0;
          &:last-child{
            margin:none;
          }
          &:hover{
            color:#F0B429;
            transition:.4s ease-in-out all;
          }
        }
        .active{
          border-bottom:1px solid #F0B429;
          color:#F0B429;

          @media screen and (max-width: 750px) {
            border-bottom: none;
          }
        }
      }
      .close{
        cursor:pointer;
        transition:.5s ease-in-out all;
        font-size:25px;
        &:hover{
          color:#f00;
          transition:.5s ease-in-out all;
        }
      }
    }
    .list{
      display:grid;
      grid-template-columns:1fr 1fr 1fr 1fr 1fr;
      // justify-content: center;
      margin-bottom:50px;
      margin-top:1000px;
      .item{
        padding:32px 22px;
        background:#fff;
        border: 1px solid #F7F7F7;
        box-sizing: border-box;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        transition:.5s ease-in-out all;
        width:95%;
        &:hover{
          box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
          transition:.5s ease-in-out all;
        }
        .name{
          font-weight:600;
          font-size:24px;
          margin-bottom:16px;
        }
        .plans-account-price{
          margin-bottom:32px;
          color:#F7C948;
          .value{
            font-size:40px;
          }
        }
        .read-more{
          color:#F7C948;
          margin-bottom:40px;
          span{
            &:hover{
              cursor:pointer;
            }
          }
        }
        .features-open{
          height:auto;
          transition:.5s ease-in-out all;
        }
        .features-close{
          height:100px;
          overflow-y:hidden;
          transition:.5s ease-in-out all;
        }
        .features{
          margin-bottom:5px;
          transition:.5s ease-in-out all;
          .bold{
            font-weight:bold!important;
          }
          div{
            margin-bottom:15px;
          }
        }
      }
      .active{
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
        button{
          background:#fff;
          border:1px solid #F7C948;
          color:#F7C948;
        }
      }
    }
    .modal-container{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height: 100%;
      overflow-y:scroll;
      .plans{
        margin-top:125px;
        .switch-container{
          display:flex;
          justify-content: space-between;
          align-items:center;
          width:130px;
          margin-bottom:35px;
          .yearly{
            margin-left:15px;
          }
          .monthly{
            margin-right:15px;
          }
        }    
        .all-features-navigation{
          margin-top:35px;
          padding-top:25px;
          padding-bottom:25px;
          cursor:pointer;
          img{
            margin-left:20px;
            transition:.25s ease-in-out all;
          }
        }
        .all-features-navigation-active{
          img{
            transform:rotate(180deg);
            transition:.25s ease-in-out all;
          }
        }
        .all-features{
          height:0;
          overflow-y:hidden;  
          transition:.4s ease-in-out all;
          .plan-details{
              .head{
                  text-align:center;
                  border-top: 1px solid #E6E6E6;
                  padding-top:50px;
                  margin:50px 0;
              }
              .double-container-1-5{
                  margin-top:120px;
                  .item{
                      .tab-header{
                          margin-bottom:10px;
                          height:75px;
                      }
                      .inner{
                          padding:24px 0 0 0;
                          margin-bottom:26px;
                          border-radius:3px;
                          .tab{
                              height:57px;
                              display:flex;
                              align-items:center;
                              position:  relative !important;
                              &:nth-child(odd){
                                  background: rgba(255, 245, 204, 0.3);
                              }
                          }
                      }
                  }
                  .quintuple-container2{
                      .item{
                          padding:0;
                          text-align:center;
                          .bold{
                              margin-bottom:10px;
                          }
                          .tab{
                              justify-content:space-around;
                              border-left: 1px solid #E6E6E6;
                          }
                          .disabled {
                            color: #b1b1b1;
                            background: #fff;
                            border: 1px #b1b1b1 solid;
                          }
                      }
                  }
                  .features{
                      .tab, .tab-header{
                          padding-left:26px;
                          border:none;
                      }
                  }
              }
          }
        }
        .all-features-active{
          height:auto;
          transition:.4s ease-in-out all;
        }
      }
      .payment{
        margin-top:150px;
        .card2{
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
          border-radius: 9px;
        }  
        .card-payment-input{
          margin:0;
          color:#7e7e7e;
          .required{
            color:#7e7e7e!important;
          }
          .label{
            display:block;
            margin-bottom:10px;
          }
          input{
            padding:12px 14px;
            border: 1px solid #D3D3D3;
            border-radius:3px;
          }
        }
        .container{
          margin: 0 auto;
          max-width: 916px;
          width: 100%;
          display:grid;
          gap: 35px;
          grid-template-columns:repeat(auto-fit, minmax(300px, 1fr));
          justify-content:space-between;
          .payment-method{
            padding:35px;
            .item{
              margin-bottom:5px;
              &:last-child{
                margin:0;
              }
              button{
                margin-right:10px;
              }
              .card-number-section {
                margin: inherit;
              }
              label {
                display:block;
                margin-bottom:10px;
                font-size: 11px;
              }
              #card-logo {
                margin-top: 2.5rem;
              }
              .payment-textfield{
                cursor: text;
                margin:0;
                color:#7e7e7e;
                font: medium -moz-fixed;
                height: 45px;
                width: 100%;
                overflow: auto;
                padding: 2px;
                border-radius:4px;
                border:1px #ccc solid;
                transition:.5s ease-in-out all;
              }
            }
            .double-item{
              display:grid;
              grid-template-columns:repeat(2, 48%);
              justify-content:space-between;
            }
            .card-details{
              display:flex;
              align-items:center;
              img{
                height:50px;
                margin-right:32px;
              }
            }
          }
          .plan-details{
            .head{
              border-bottom: 1px solid #E8E8E8;
              padding:21px 27px 14px 27px;
            }
            .plan-details-container{
              padding:28px;
              .item{
                margin-bottom:10px;
                display:flex;
                justify-content:space-between;
                padding: 0 !important;
                .bull{
                  margin-right:10px;
                }
              }
              .items-container{
                margin-bottom:20px;
              }
              .font-18{
                border-top: 1px solid #E8E8E8;
                border-bottom: 1px solid #E8E8E8;
                padding:15px 0;
                .item{
                  margin:0;
                }
              }
            }
          }
        }
      }
      .confirmation{
        text-align:center;
        margin-top:150px;
        img{
          width:25%;
        }
        .font-24{
          margin:8px 0;
        }
        button{
          margin-top:20px;
        }
      }
    }
    
  }
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */

/* tablet screen */
@media screen and (max-width: 1024px) {
  .plans-account .users {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 40px 0;
  }
}

/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */

/* mobile screen */
@media screen and (max-width: 600px) {
  .plans-account .users {
    display: grid;
    grid-template-columns: 1fr;
    margin: 40px 0;
  }
}

.plans-wrapper {
padding: 20px;
max-width: 1500px;
margin: 0 auto;
}
.plans-container {
width: 100%;
display: grid;
grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
justify-content: space-between;
gap: 30px;

.item {
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 3px;
  .popular {
    border-radius: 2px 2px 0 0;
    text-align: center;
    padding: 4px;
    opacity: 0;
  }
  .content {
    padding: 50px 24px 0 24px;
    .name {
      margin-bottom: 8px;
    }
    .price2 {
      margin: 20px 0 24px 0 !important;
      font-size: none !important;
    }
    .cta {
      width: 100%;
    }
    .features {
      margin-top: 32px;
      div {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        img {
          margin-right: 16px;
        }
      }
      div:last-child {
        margin: 0;
      }
    }
    .see-more {
      padding: 20px 10px;
      text-align: center;
      margin-top: 15px;
      opacity: 0;
    }
  }
}
.item-active {
  border: 1px solid #fce588;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  .content {
    .see-more {
      opacity: 1;
    }
  }
}
.active-plan {
  color: #b1b1b1;
  border: 1px solid #e4e2e2;
  .content {
    .price2 {
      color: #b1b1b1;
    }
    .full-input {
      color: #b1b1b1;
      background: #fff;
      border: 1px #b1b1b1 solid;
      transition: 0.4s ease-in-out all;
      pointer-events: none;
    }
    .features {
      color: #b1b1b1;
    }
  }
}
}
