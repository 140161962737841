@media screen and (max-width: 768px) {
  .backIcon {
    width: 25px !important;
  }
  .back-btn-cont{
    margin-top: 5% !important;
  }
  .back-text{
    font-size: 13px !important;
  }
}
