.create_list_title {
  font-size: 18px;
  margin-top: 10px;
}

.back_button {
  appearance: none;
  border: none;
  background-color: #fff;
  height: 30px;
  padding: 0;
  text-align: left;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  i {
    padding-right: 4px;
  }
}

label {
  margin: 0;
  margin-right: 8px;
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  text-transform: capitalize;
}

.helper_text {
  font-size: 12px;
  margin-top: 4px;
}

.radio_button_container {
  flex-direction: row !important;
}

.change_button {
  margin-top: 20px;
  border: 1px solid;
  font-family: 'Mulish', sans-serif;
  font-size: 16px !important;
  text-align: center;
  position: relative;
  padding: 5px 0 !important;
  appearance: none;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
