

  .Map .node--internal circle {
    fill: rgb(155, 82, 82);
  }

  .Map .node--internal text {
    text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
  }

  .Map {
    position: relative;
    width:100%;
    height:100%;
    background:#fff;
  }


.node {
  font: 300 18px "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*fill: #FBB03B;*/
  font-weight: bold;
}

.node:hover {
  fill: #000;
}

.link {
  /*stroke: steelblue;*/
  stroke-opacity: 0.4;
  fill: none;
  pointer-events: none;
  stroke-width: 3px;
}

.node:hover,
.node--source,
.node--target {
  font-size: 30px;
  font-weight: 900;
}

.node--source {
  fill: #2ca02c;
  font-size: 30px;
}

.node--target {
  fill: #d62728;
  font-size: 30px;
}

.link--source,
.link--target {
  stroke-opacity: 1;
  stroke-width: 5px;
}

.link--source {
  stroke: #d62728;
}

.link--target {
  stroke: #2ca02c;
}
div.tooltip {
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 2px;
  font: 20px sans-serif;
  background: rgba(0, 100, 100, .4);
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}
.svg-container {
  /*display: inline-block;*/
  /*position: relative;*/
  /* margin-top: -150px; */
  /*padding-bottom: 50%;*/
  /*!*vertical-align: top;*!*/
  /*overflow: hidden;*/
}
.svg-content {
  display: inline-block;
  position: relative;
  transform-origin: inherit;
  transform-style: preserve-3d;
}

svg circle {
  fill: red;
}

  .Map .radial_node circle {
    fill: #999;
  }

  .Map .radial_node text {
    font: 10px sans-serif;
  }

  .Map .radial_node--internal circle {
    fill: rgb(155, 82, 82);
  }

  .Map .radial_node--internal text {
    text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
  }

  .Map .radial_node_link {
    fill: none;
    stroke: rgb(23, 30, 136);
    stroke-opacity: 0.4;
    stroke-width: 1.5px;
  }
