.auth {
  overflow: hidden;
}

.auth__sidebar {
  /* background: url(../../assets/images/authbg.png);
    background-size: contain; */
  height: 100vh;
  width: 50%;
  background-repeat: no-repeat;
  position: fixed;
}

.auth__sidebar .layer {
  /* background: linear-gradient(180deg, rgba(240, 180, 41, 0.82) 0%, rgba(222, 145, 29, 0.82) 100%); */
  mix-blend-mode: normal;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  padding: 50px 0;
}

.auth__sidebar img {
  width: 150px;
}

.auth__sidebar .title {
  color: #fff;
  margin-top: 40px !important;
  font-size: 40px;
}

.auth__sidebar .description {
  color: #fff;
  font-size: 18px;
  line-height: 160%;
  margin-top: 30px;
  width: 80%;
}

.auth__component {
  padding: 50px 0;
}

.auth__component .route__text {
  color: #3b3b3b;
}

.auth__component .link__text {
  color: #f0b429;
}

.auth__component .auth__form__container {
  width: 80%;
  margin: 70px auto 40px auto;
}

.auth__component .auth__form__container h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.auth__component .auth__form__container .Oauth {
  width: 100%;
  border-radius: 3px;
  color: #fff;
  padding: 11px 0;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 600;
  cursor: pointer;
}

.auth__component .auth__form__container .Oauth img {
  position: absolute;
  left: 10px;
  width: 32px;
}

.auth__component .auth__form__container .podio {
  background: #5088ac;
}
.auth__component .auth__form__container .salesforce {
  background: #07a3e1;
}
.auth__component .auth__form__container .shopify {
  background: #a3dd69;
}

.auth__component .line-horizontal {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 40px 0 20px;
}

.auth__component .line-horizontal span {
  background: #fff;
  padding: 0 10px;
  font-weight: bold;
}

.auth__component form label {
  font-size: 14px;
  color: #222222;
}

.auth__component form label ~ small {
  font-size: 12px;
}
.auth__component form input[type="text"],
.auth__component form input[type="password"],
.auth__component form input[type="email"],
.auth__component form input[type="phone"] {
  height: 44px;
}

.auth__component form .field-icon2 {
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  right: 10px;
  font-size: 13x;
}

.auth__component .auth-btn {
  border: none;
  background: #f0b429;
  border-radius: 3px;
  color: #fff;
}

.auth__component .logo {
  color: #fff;
}
.auth__component form input[type="checkbox"] {
  border: #f0b429 !important;
  display: inline-block !important;
  height: 20px !important;
  width: 20px !important;
  appearance: auto !important;
}
.auth__component form input[type="checkbox"]:checked {
  accent-color: #f0b429 !important;
}

.auth__component .verify__result i {
  font-size: 100px;
  margin: 50px 0;
  color: #f0b429;
}

.auth__component .verify__result a {
  font-size: 20px;
  position: relative;
  top: 30px;
  text-decoration: underline;
  color: #f0b429;
}

@media screen and (max-width: 991px) {
  .auth__sidebar {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .auth__component .auth__form__container {
    width: 95%;
    margin: 70px auto 40px auto;
  }
}
