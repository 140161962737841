#editor_container {
  position: relative;
  background: whitesmoke;
  position: relative;
  overflow-y: scroll;
  padding: 10px 0;
  padding-bottom: 30px;
}

#editor_container p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

#document-editor {
  min-height: 1000px;
  overflow: auto;
  margin: 0 auto;
  width: 95%;
}

#document-editor .page:not(:first-of-type) {
  margin-top: 60px;
}

#document-editor[orientation='landscape'] {
  min-height: 450px;
  max-height: 900px;
}

#document-editor .editor_header .ck-editor__editable_inline {
  min-height: unset !important;
}

#document-editor .editor_footer .ck-editor__editable_inline {
  min-height: unset !important;
}

#document-editor .scale {
  width: 100%;
  overflow: auto;
  margin: 0 auto;
}

#document-editor .page {
  margin: 0 auto;
  background-color: #fff !important;
}

#document-editor .ck-focused {
  border: 1px solid #ccced1;
  box-shadow: none;
}

#document-editor .editor_header {
  min-height: 3.8cm;
  max-height: 500px;
  position: relative;
  width: 100%;
  flex: 1 1 auto;
}

#document-editor .editor_body {
  min-height: 15.5cm;
  width: 100%;
  background-color: #fff !important;
}

#document-editor .editor_footer {
  min-height: 4cm;
  max-height: 500px;
  position: relative;
  width: 100%;
  flex: 1 1 auto;
}

#document-editor .editor_body .ck-editor__main {
  height: 20cm;
  overflow-y: scroll;
}

#document-editor .editor_body .ck-editor__main [role='textbox'] {
  min-height: 100% !important;
}

#document-editor .editor_header .ck-content,
#document-editor .editor_footer .ck-content {
  padding: 30px 70px;
}
#document-editor .editor_body .ck-content {
  padding: 10px 70px;
}

// visual indicator for tables and images
#editor_container .ck-content img,
#editor_container .ck-content table {
  border: 2px solid #28a745 !important;
}

#document-editor[size='A3'] .page {
  width: 29.7cm;
}

#document-editor[size='A4'] .page {
  width: 21.9cm;
}

#document-editor[size='A5'] .page {
  width: 14.8cm;
}

#document-editor[size='A3'][orientation='landscape'] .page {
  width: 42cm;
}

#document-editor[size='A4'][orientation='landscape'] .page {
  width: 30.8cm;
}

#document-editor[size='A5'][orientation='landscape'] .page {
  width: 21cm;
}

#document-editor .controls {
  padding: 0px 96px;
  position: relative;
  background-color: #fff;
  border: 1px solid #ccced1;
  border-bottom: none;
  height: 35px;
  width: 100%;
  align-items: center;
}

#document-editor .controls-footer {
  border-top: none;
}

.deletePage {
  position: absolute;
  font-size: 24px;
  top: 0;
  right: 10px;
  cursor: pointer;
  z-index: 10;
}

.deletePage button {
  height: 24px;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

.page_number_header,
.page_number_footer {
  display: none;
  padding: 3px 50px;
}
