/* New Draft message style*/

.auth {
    overflow: hidden;
}

.auth__sidebar {
    height: 100vh;
    width: 50%;
    /* background-repeat: no-repeat; */
    position: fixed;
}

.auth__sidebar .layer {
    mix-blend-mode: normal;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    padding: 50px 0;
}

.auth__sidebar img {
    width: 150px;
}

.auth__sidebar .title {
    color: #fff;
    margin-top: 100px;
    font-size: 40px;
}

.auth__sidebar .description {
    color: #fff;
    font-size: 18px;
    line-height: 160%;
    margin-top: 30px;
}

.auth__component {
    padding: 50px 0;
}

.auth__component .route__text {
    color: #3b3b3b;
}

.auth__component .link__text {
    color: #f0b429;
}

.auth__component .auth__form__container {
    width: 80%;
    margin: 70px auto 40px auto;
}

.auth__component .auth__form__container h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

.auth__component .auth__form__container .Oauth {
    width: 100%;
    border-radius: 3px;
    color: #fff;
    padding: 11px 0;
    margin: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 600;
    cursor: pointer;
}

.auth__component .auth__form__container .Oauth img {
    position: absolute;
    left: 10px;
    width: 32px;
}

.auth__component .auth__form__container .podio {
    background: #5088ac;
}
.auth__component .auth__form__container .salesforce {
    background: #07a3e1;
}
.auth__component .auth__form__container .shopify {
    background: #a3dd69;
}

.auth__component .line-horizontal {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 40px 0 20px;
}

.auth__component .line-horizontal span {
    background: #fff;
    padding: 0 10px;
    font-weight: bold;
}

.auth__component form label {
    font-size: 14px;
    color: #222222;
}

.auth__component form label ~ small {
    font-size: 12px;
}
.auth__component form input[type="text"],
.auth__component form input[type="password"],
.auth__component form input[type="email"],
.auth__component form input[type="phone"] {
    height: 44px;
}

.auth__component form .field-icon2 {
    cursor: pointer;
    position: absolute;
    bottom: 16px;
    right: 10px;
    font-size: 13x;
}

.auth__component .auth-btn {
    border: none;
    background: #f0b429;
    border-radius: 3px;
    color: #fff;
}

.auth__component .logo {
    color: #fff;
}
.auth__component form input[type="checkbox"] {
    border: #f0b429;
    display: inline-block;
    width: 20px;
}

.auth__component .verify__result i {
    font-size: 100px;
    margin: 50px 0;
    color: #f0b429;
}

.auth__component .verify__result a {
    font-size: 20px;
    position: relative;
    top: 30px;
    text-decoration: underline;
    color: #f0b429;
}

@media screen and (max-width: 991px) {
    .auth__sidebar {
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .auth__component .auth__form__container {
        width: 95%;
        margin: 70px auto 40px auto;
    }
}

@media screen and (max-width: 600px) {
    .modal_cont {
        width: 80% !important;
    }

    .modal_cont_conf {
        width: 80%;
    }

    .box-message {
        flex-direction: column;
    }
    .input_field {
        width: 100% !important;
    }
    .edit_cont {
        width: 100% !important;
        padding: 0 !important;
    }
    .signature_mobile {
        flex-direction: column !important;
    }
    .signature {
        width: 350px !important;
    }
    .btn_mobile {
        width: 100% !important;
        margin-top: 3% !important;
    }
    .editor_cont {
        width: 100% !important;
    }
    .logo_mobile {
        width: 30% !important;
    }
}

.logo_cont {
    padding: 1% 7%;
}

.box-message {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 7%;
    height: inherit;
}
.input_field {
    width: 35%;
}
.form_subject {
    font-size: 20px;
    font-weight: 600;
}
.from_cont {
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
}
.formRow {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.editor_cont {
    width: 63%;
}
.edit_cont {
    border: 1px solid #e1e6ed;
    padding: 2rem;
    margin-bottom: 3%;
    border-radius: 15px;
}
.signature {
    width: 250px;
}

.generate_btn {
    background-color: #f0b429;
    border: none;
    border-radius: 4px;
    color: white;
    height: 50px;
}

.cancel_btn {
    background-color: #cfcfcf;
    border: none;
    border-radius: 4px;
    color: white;
    height: 50px;
}

.modal_bg {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
}

.modal_cont_conf {
    background-color: white;
    border-radius: 10px;
    width: 25%;
    padding: 2%;
}

.modal_cont {
    background-color: white;
    border-radius: 10px;
    width: 25%;
    padding: 1% 1% 2% 1%;
}
