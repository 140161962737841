.summary-container {
  width: 80vw;
}
.summary-tabs {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 1.2rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.summary-tabs .item {
  text-align: center;
  padding: 10% 10%;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.summary-tabs .item img {
  height: 3vh;
  margin-bottom: 2vh;
}
.summary-tabs .detailsCont {
  text-align: left;
  margin-left: 5%;
}
.summary-tabs .item .label {
  font-size: 1rem;
  color: #7e7e7e;
}
.summary-tabs .item .value {
  font-size: 32px;
  color: #000;
  font-weight: 700;
}

.summary .table-ag-grid {
  z-index: -1000;
  margin-top: 3vh;
}

@media only screen and (max-width: 600px) {
  .summary-container {
    width: 100%;
  }
  .auditheaderCont {
    margin: 5% 0 !important;
  }
  .selectDiv {
    margin-top: 3%;
  }
  .auditHead {
    padding: 0 !important;
  }

  .summary-tabs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 15px !important;
    padding: 2% !important;
    margin-top: 3% !important;
    box-shadow: none;
  }

  .summary-tabs .item {
    width: 100% !important;
    padding: 5% !important;
    border-radius: 10px !important;
    box-shadow: 0px 1px 24.9px 0px #00000014;
    display: flex;
    margin: 2% 0;
    border: 1px solid #e1e1e1 !important;
  }

  .summary-tabs .item-first {
    border: 1px solid #e1e1e1 !important;
  }

  .summary-tabs .item-last {
    border: 1px solid #e1e1e1 !important;
  }
  .item-middile {
    border: 1px solid #e1e1e1 !important;
  }
  .audit-tab-btn {
    display: none !important;
  }
  .navigation {
    overflow-x: auto;
    width: 100% !important;
  }
  .navigation span {
    font-size: 12px !important;
  }
  .value {
    font-size: 20px !important;
  }
  .inner {
    width: 75vw !important;
    padding: 5%;
  }
  .audit-tab-btn-mobile {
    display: flex !important;
  }
  .summary-tabs .image {
    margin-right: 5%;
  }
  .dashboard_title {
    font-size: 16px !important;
  }
  .sync__btn {
    font-size: 0.8rem !important;
  }
}
