.integration-workflow-title h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  color: #010e20;
  text-align: center;
}
.integration-avatar-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: 20px;
}
.tab-content {
  position: relative;
  height: fit-content;
  z-index: 9;
}

.integration-avatar-cont img {
  width: 90%;
  max-width: 217px;
}

.integration-avatar-cont p {
  font-size: 16px;
  line-height: 24px;
  color: #010e20;
  display: none;
}

.integration-avatar-cont p span,
.integration-avatar-sub span {
  font-size: 16px;
  color: #f0b429;
}

.integration-avatar-sub {
  color: #98989a;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  text-align: center;
}

.tab-cont {
  width: 100%;
  height: 55px;
  border-radius: 50px;
  padding: 0 8px;
  margin-top: 35px;
  margin-bottom: 25px;
  background-color: #fafafa;
}

.tab-details{
  height: fit-content;
}

.tab-group {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background: transparent;
}

.tab-group button {
  width: 33.333%;
  text-align: center;
  border: none;
  outline: none;
  background: transparent;
  z-index: 2;
  color: #000000;
  font-size: 16px;
  line-height: 18.7px;
  font-family: "Rubik", sans-serif;
  cursor: pointer;
}

.active-tab {
  background-color: #f0b429;
  border-radius: 50px;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 500px) {
  .tab-cont {
    width: 330px;
    margin: 35px auto 25px;
  }
}

@media screen and (min-width: 700px) {

  .integration-workflow-cont {
    padding: 60px 0;
  }
  .integration-workflow-title h2 {
    font-size: 30px;
    line-height: 37px;
    max-width: 597px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .integration-workflow-title h2 {
    font-size: 36px;
    line-height: 43px;
    text-align: left;
    width: 45%;
    max-width: 697px;
    margin: 0;
  }
  .integration-workflow-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .integration-avatar-cont p {
    display: inline;
  }
  .integration-avatar-cont {
    margin: 0;
  }
  .integration-avatar-sub {
    display: none;
  }
  .integration-workflow-cont {
    padding: 80px 0 60px;
  }
  .tab-cont {
    margin: 35px 0 25px;
  }
}
