.frequently-asked-questions{
  padding:0 7.5vw;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.px-4 {
  padding: 0 4rem;
}
.card {
  width: 320px;
  margin: 1rem;
}
.header__card {
  background-color: #262a33;
  padding: 1rem;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.color__background {
  background-color: #fcaf3b;
  position: absolute;
  width: 100%;
  height: 26vh;
  top: 0;
  left: 0;
}
.pricee {
  font-size: 72px !important;
  margin: 0 0.5rem !important;
}
.month {
  align-self: flex-end;
  margin-bottom: 1rem !important;
}
.currency {
  margin-top: 0.5rem !important;
}
.p-32 {
  padding: 3.2rem !important;
}
.pb-12 {
  padding-bottom: 1.2rem !important;
}
