.override div.MuiDialog-container.MuiDialog-scrollPaper > div {
  background-color: #fff;
}

.wrapper {
  padding: 0 !important;
}

.container {
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 20px;

  .card_brand {
    width: 70px;
    height: 51px;
  }

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: #000;

    &.created {
      color: #7e7e7e;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #e8e8e8;
  }

  .mask {
    text-align: left;
    font-weight: bold;
    color: #515151;
  }

  .control {
    margin-left: auto;
  }
}
