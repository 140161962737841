.sync__table {
  width: 100%;
  background: #fff;
  min-height: 300px;
  border-radius: 6px;
  margin-top: 30px;
}

.sync__table table th {
  border-top: 0;
  padding: 20px;
  font-weight: 600;
  text-align: left;
  font-size: 14px;
}

.sync__table table tr td {
  text-align: left;
  border-top: 1px solid #f1f0f4;
}

.sync__table table tr td a {
  color: #000;
}

.sync__table table tr td a:hover {
  text-decoration: none;
}

.sync__table table tr td a:focus {
  background: transparent;
}
.dropdown-toggle {
  font-size: 16px;
  margin-top: -30px;
  background-color: #FFFAEB;
  padding: 5% 10%;
  color: "#DE911D"
}
.sync__table table tr td .dropdown-toggle::after {
  display: none;
}

.sync__table table tr td .dropdown-menu {
  padding: 0;
}

.sync__table table tr td .dropdown-menu span {
  font-size: 14px;
  cursor: pointer;
  padding: 6px 10px;
}

.sync__table table tr td .dropdown-menu span {
  display: flex;
}

.sync__table table tr td .dropdown-menu span img {
  position: relative;
  top: 1px;
  margin-right: 10px;
}

.sync__table table tr td .title {
  color: #3b3b3b;
  margin-bottom: 0;
}

.sync__table table tr td .num {
  color: #9e9e9e;
  font-size: 12px;
}

.sync__table table tr td .count {
  font-weight: bold !important;
}

.sync__table table tr td .info {
  color: #27ab83;
  background: #effcf6;
  border-radius: 12.5px;
  padding: 5px 10px;
  font-size: 12px;
}

.sync__table .table__popup {
  position: relative;
}

@media screen and (max-width:600px){
  .table-responsive {
    width: 88vw !important; /* Set the width of the table container */
    overflow-x: auto !important; /* Enable horizontal scrolling */
  }
  .table {
    width: 100% !important; /* Ensure the table takes full width of its container */
  }
  .created_on{
    padding: 20px 0 !important;
  }
  .action-btn-mobile{
    margin-top: 0 !important;
  }
}
