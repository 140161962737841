.auth__forms__cont {
  min-height: 100vh;
  width: 100%;
  background-color: #f0b429;
  padding: 30px 5vw;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.auth__title__cont {
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.auth__title__cont h2 {
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 400;
}

.auth__title__cont h2 span a {
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  color: white;
}

.home__link {
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  color: white;
  line-height: 24px;
}

.home__link:hover {
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  color: white;
}

.auth__form__wrapper {
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create__form {
  width: 100%;
  padding: 30px 24px;
  max-width: 440px;
  border-radius: 20px;
  background-color: white;
}

.create__logo__cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create__logo__cont img {
  width: 150px;
}

.create__logo__cont h2 {
  color: #222222;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 6px 0 8px;
  text-align: center;
}

.create__logo__cont p {
  font-size: 15.5px;
  font-weight: 400;
  color: #7e7e7e;
  line-height: 24px;
  text-align: center;
  margin: 0;
}

.create__inputs__cont {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}

.inputs__cont {
  display: flex;
  flex-direction: column;
  justify-content: first baseline;
  gap: 4px;
}

.inputs__cont label {
  color: #0a0d14;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.inputs__cont__input,
.create__pass__cont {
  border: 1px solid #e2e4e9;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px #e4e5e73d;
  padding: 0 0 0 12px;
  outline: none;
  font-size: 14px;
  color: #222222;
  background: transparent;
}

.create__pass__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.check__input {
  appearance: auto;
  height: 13px;
  width: 13px;
}

.create__eye__cont {
  height: 100%;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.create__pass__cont input {
  height: 100%;
  flex-grow: 1;
  border: none;
  font-size: 14px;
  padding: 0 0 0 12px;
  outline: none;
  color: #222222;
  background: transparent;
}

.create__pass__cont img {
  width: 20px;
}

.inputs__cont__input::placeholder,
.create__pass__cont input::placeholder {
  color: #868c98;
}

.or__divide__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin: 20px 0 0;
}

.or__divide__cont p {
  color: #b9c0c6;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

.or__divide__cont div {
  border-top: 0.5px dashed #b9c0c6;
  flex-grow: 1;
}

.create__podio {
  width: 100%;
  border-radius: 12px;
  border: 0.5px solid #121212;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  outline: none;
  cursor: pointer;
  background: transparent;
}

.create__podio img {
  width: 27px;
  height: 29px;
}

.create__podio p {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #121212;
  margin: 0;
  font-family: "Rubik", sans-serif;
}

.create__form__terms {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  margin: 30px 0;
}

.create__form__terms__label {
  font-size: 14px;
  line-height: 20px;
  color: #0a0d14;
  font-weight: 500;
}

.create__form__terms__label span a {
  color: #f0b429;
}

.form__bottom__button {
  background: #121212;
  height: 53px;
  display: flex;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 20px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 16px 0px #0000001a;
  border-radius: 12px;
}
.succ__but__cont {
  margin-top: 27px;
}
.forgot__pass__link {
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  color: #525866;
  line-height: 20px;
  cursor: pointer;
}

.forgot__pass__link__cont {
  margin: 25px 0;
}
.log__but__cont {
  margin-bottom: 20px;
}
@media screen and (min-width: 700px) {
  .auth__title__cont h2 {
    font-size: 16px;
  }
  .auth__forms__cont {
    padding: 40px 5vw;
    gap: 50px;
  }
  .create__form {
    padding: 37px 30px;
  }
}

@media screen and (min-width: 1200px) {
  .create__form {
    /* max-height: calc(100vh - 134px); */
    overflow-y: scroll;
  }

  .create__form {
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    overflow: -moz-scrollbars-none; /* For Firefox */
    /* overflow: hidden; */
  }

  .create__form::-webkit-scrollbar {
    display: none;
  }
}
