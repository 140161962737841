.audit__page .dropdown-toggle::after {
  position: relative;
  left: 10px;
  top: 2px;
  color: #9e9e9e;
}

.audit__page select {
  width: 50%;
  border-radius: 10px;
}

.audit__page select.dropdown {
  border: none;
  background: transparent;
  border-bottom: 1px solid #000;
  border-radius: 0px;
}

.audit__page .input-group {
  width: 25%;
  margin-left: auto;
  border: 1px solid #000;
  border-radius: 4px;
}

.audit__page .input-group-text {
  background: #fff;
  border: none;
}

.audit__page input {
  border: none;
}

.audit__page img {
  cursor: pointer;
}

.load__audit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auditHeader {
  font-weight: 600;
  font-size: 18px;
}

.selectDiv {
  display: flex;
  align-items: center;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  margin-right: 1%;
  padding: 0 1% 0 0;
}

.audit .id {
  color: #cb6e17;
  font-size: 15px;
}
.audit-tabs {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 1.2rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.audit-tabs .item {
  text-align: center;
  padding: 10% 10%;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.audit-tabs .item img {
  height: 3vh;
  margin-bottom: 2vh;
}
.audit-tabs .detailsCont {
  text-align: left;
  margin-left: 5%;
}
.audit-tabs .item .label {
  font-size: 1rem;
  color: #7e7e7e;
}
.audit-tabs .item .value {
  font-size: 32px;
  color: #000;
  font-weight: 700;
}

.audit .table-ag-grid {
  z-index: -1000;
  margin-top: 3vh;
}

.tabCont {
  margin-bottom: 5% !important;
  border-bottom: 1.5px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}
.audit .table-ag-grid .navigation {
  position: relative !important;
  text-align: center;
  display: flex;
  width: 70%;
}
.audit .table-ag-grid .navigation span {
  padding: 1vh 1.5vw;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
}
.audit .table-ag-grid .navigation span.active {
  color: #f7c948;
  border-bottom: 2.5px #f7c948 solid;
}
.audit .table-ag-grid .ag-grid-content {
  z-index: -1000000 !important;
}

.audit .add-user {
  text-align: right;
}
.audit button {
  background: white;
  outline: none;
  text-decoration: none;
  border: none;
  color: #f0b429;
  font-size: 14px;
  padding: 0;
}
.audit button img {
  margin-right: 0.5vw;
  width: 1.25vw;
}

.audit .add-user-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
}
.audit .add-user-container .background {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.audit .add-user-container .inner {
  width: 25vw;
  background: #fff;
  z-index: 1000;
  margin: 20vh auto;
  position: relative;
  border-radius: 7px;
  padding: 2vh 1.5vw;
}
.audit .add-user-container .inner input {
  display: block;
  padding: 2vh 1vw;
  width: 100%;
  border-radius: 5px;
  border: 1px #dadada solid;
  margin-bottom: 3vh;
}
.audit .add-user-container .inner .title {
  font-size: 2vw;
  font-weight: bold;
  margin-bottom: 3vh;
}
.audit .add-user-container .inner .subtitle {
  color: grey;
  margin-bottom: 1vh;
}
.audit .add-user-container .buttons {
  text-align: right;
  margin-bottom: 2vh;
}
.audit .add-user-container .buttons span {
  color: #f7c948;
  margin-left: 1vw;
  cursor: pointer;
}

.tab-content {
  display: block;
  background: rgba(0, 0, 0, 0.4);
  height: 50vh;
  border-radius: 6px;
  padding: 5vh 0;
  margin-top: 0;
  margin-bottom: 7.5vh;
}
.disappear {
  display: none;
}
.audit-tab-btn-mobile {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .auditheaderCont {
    margin: 5% 0 !important;
  }
  .selectDiv {
    margin-top: 3%;
  }
  .auditHead {
    padding: 0 !important;
  }

  .audit-tabs {
    display: flex;
    flex-wrap: wrap;
    border-radius: 15px !important;
    padding: 2% !important;
  }

  .item {
    width: 50% !important;
    padding: 5% !important;
    border-radius: 0 !important;
  }

  .item-first {
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
  }

  .item-last {
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-right: none !important;
  }
  .item-middile {
    border: none !important;
  }
  .audit-tab-btn {
    display: none !important;
  }
  .navigation {
    overflow-x: auto;
    width: 88vw !important;
  }
  .navigation span {
    font-size: 12px !important;
  }
  .value {
    font-size: 20px !important;
  }
  .inner {
    width: 75vw !important;
    padding: 5%;
  }
  .audit-tab-btn-mobile {
    display: flex !important;
  }
  .tabCont {
    width: 88vw !important;
    overflow-x: auto;
  }
  .hooks_manager {
    width: 120px !important;
  }
  .raw_data_name {
    width: 80px !important;
  }
  .label {
    font-size: 13px !important;
  }
}
