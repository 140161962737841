.titleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  h4 {
    font-size: 18px;
    margin: 0;
  }
}
