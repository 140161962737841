.pending-invite {
  color: #626262;
  background: #e1e1e1;
  border-radius: 12.5px;
  font-size: 12px;
  padding: 5px 15px;
  margin-left: 5%;
  // position: absolute;
  // left: 13px;

  &.Active {
    background-color: #effcf6;
    color: green;
  }
}

.member-status {
  color: #626262;
  background: #e1e1e1;
  border-radius: 12.5px;
  font-size: 12px;
  padding: 5px 15px;
  // position: absolute;
  // left: 13px;

  &.Active {
    background-color: #fffaeb;
    color: #f0b429;
  }
}
.teams .header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5px 0;
}
.teams .header .highlighted {
  color: #fcaf3b;
  font-weight: bold;
  padding: 0 !important;
  margin: 0 !important;
}
.teams .right {
  text-align: right;
  padding: 0;
}

.teams .users {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
  gap: 10px 10px;
  min-height: 55vh;
  overflow-y: auto;
}
.teams .users .item {
  position: relative;
  width: 45%;
  margin: 2%;
  padding: 30px 16px 17.5px 16px;
  // box-shadow: 0px 2px 10px rgba(1, 1, 0, 0.05);
  box-shadow: 0px 1px 25px 0px #0000000d;
  border-radius: 10px;
  text-align: center;
  // border: 1px solid #0000000d;
  height: 270px !important;
}
.teams .users .item button {
  margin-top: 10px;
  font-size: 12px;
}
.teams .users .item .image {
  width: 56px;
  height: 56px;
  border-radius: 100px;
  margin: 7.5px auto;
  background: grey;
}
.teams .users .item .text-container {
  height: 75px;
}
.teams .users .item .name {
  font-weight: bold;
  font-size: 18px;
}
.teams .users .item .email {
  color: grey;
  font-size: 14px;
}

.teams .input-fields {
  display: grid;
  grid-template-columns: 3fr 1fr !important;
}

/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */

/* tablet screen */
@media screen and (max-width: 1024px) {
  .teams .users {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 40px 0;
  }
}

/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */
/* break */

/* mobile screen */
@media screen and (max-width: 600px) {
  .teams .users {
    display: grid;
    grid-template-columns: 1fr;
    margin: 40px 0;
  }
  .teams .header {
    display: none !important;
  }
  .item {
    width: 100% !important;
  }
  .teams .users {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0;
    gap: 10px 10px;
    min-height: 55vh;
    overflow-y: none;
  }
}

.user-actions {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  gap: 0px 10px;
  justify-content: center;
}

.role_details {
  margin-top: 10px;
  display: grid;
  gap: 4px;

  h3 {
    font-size: 15px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;

    span {
      font-weight: 600;
    }
  }
}
