.container {
  background-color: #fff;
}

.container .top_bar {
  margin: 0 auto;
  max-width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

@media screen and (max-width: 900px) {
  .container .top_bar {
    max-width: 95%;
  }
}

.top_bar h1 {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #3b3b3b;
}

.top_bar button {
  width: 204px;
  padding: 0;
  height: 48px;
  background: #0277bd;
  border-radius: 3px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Mulish', sans-serif;
  color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_bar button:hover {
  opacity: 0.9;
}

.top_bar button i {
  padding-right: 10px;
}

.table_container {
  margin: 0 auto;
  margin-top: 30px;
  width: 100%;
  max-width: 85%;
}

@media screen and (max-width: 900px) {
  .table_container {
    max-width: 95%;
  }
}

.select_template_category {
  margin-bottom: 12px;
}

.select_template_category select {
  appearance: none !important;
  font-family: 'Mulish', sans-serif;
  font-size: 18px;
  line-height: 23px;
  width: 230px;
  height: 50px;
  border: 1px solid #b1b1b1;
  border-radius: 8px;
  text-indent: 27px;
  background-image: url('../../../../assets/icons/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: 208px 20px;
  padding-right: 10px;
}
