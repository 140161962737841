.outer-cont,
.pricing__section__cont {
  padding: 35px 0;
}

.pricing__section__cont

.step-text-cont,
.pricing__title__cont {
  width: 100%;
  max-width: 797px;
  margin: 0 auto;
}
.step-text-cont h2,
.pricing__title__cont h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  color: #010e20;
  text-align: center;
}

.step-text-cont h2 span,
.pricing__title__cont h2 span {
  color: #f0b429;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
}

.step-text-cont p,
.pricing__title__cont p {
  color: #010e20a6;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 15px;
}

.step-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px 0;
  margin-top: 20px;
}

.step {
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.05);
  background-color: white;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
}

.step img {
  width: 44px;
}

.step p {
  color: #010e20a6;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}

.started-innovation-button {
  background-color: #f0b429;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border: none;
  outline: none;
  margin: 30px auto 0;
  width: 60%;
  min-width: 211px;
  max-width: 233px;
  text-decoration: none !important;
}

.started-innovation-button:hover {
  opacity: 0.8;
}

.started-innovation-button p,
.std_plan_but p,
.std_serv_but p {
  font-size: 15px;
  line-height: 24px;
  color: #010e20;
  margin: 0;
  font-family: "Rubik", sans-serif;
}

.started-innovation-button img,
.std_plan_but img,
.std_serv_but img {
  width: 24px;
  height: 24px;
}

.pricing__plans {
  width: 100%;
  max-width: 778px;
  margin: 30px auto;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 20px;
}
.standard__plan {
  width: 100%;
  padding: 25px;
  background: #071326;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}
.service__plan {
  width: 100%;
  padding: 25px;
  border: 0.5px solid #e1e6ed;
  background: transparent;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}

.serv__grid__cont {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  box-shadow: 0px 4px 15px 0px #0000000d;
  border-radius: 8px;
}
.double__section {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.serv__grid__cont h3 {
  color: #121212;
  font-size: 13px;
  line-height: 16px;
  color: #121212;
  font-weight: 400;
}

.serv__grid__cont p {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #010e20;
}
.serv__grid__cont p span {
  font-size: 11.5px;
  font-weight: 400;
  line-height: 13.4px;
  color: #010e20;
}
.email__price {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3px;
}
.cloning__price {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 3px;
  border-top: 0.5px solid #7e7e7e2e;
}

.standard__plan h2 {
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}
.standard__plan h5 {
  color: #cfcfcf;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.52px;
  text-align: center;
  padding-top: 5px;
  max-width: 285px;
  margin: 0 auto;
}

.std__h3__cont {
  width: 100%;
  border-bottom: 0.5px dashed #7e7e7e4d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 0;
  margin-bottom: 25px;
}

.service__title__cont {
  width: 100%;
  border-bottom: 0.5px dashed #7e7e7e6b;
  padding: 0 0 22px;
  margin-bottom: 25px;
}

.service__title__cont h2 {
  color: #010e20;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}
.service__title__cont h5 {
  color: #344051;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.52px;
  text-align: center;
  padding-top: 5px;
  max-width: 285px;
  margin: 0 auto;
}

.std__h3__cont h3 {
  font-size: 28px;
  line-height: 34px;
  color: #f0b429;
  font-weight: 700;
}

.std__plan__list li {
  display: flex;
  align-items: flex-start;
  color: white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400 !important;
}

.std__plan__list li::before {
  content: url("../../Images//home__price__list__icon.svg");
  margin-right: 0.5rem;
}

.std__plan__list {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.std_plan_but {
  background-color: #f0b429;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border: none;
  outline: none;
  margin: 30px auto 0;
  width: 100%;
  cursor: pointer;
  text-decoration: none !important;
}
.std_serv_but {
  background: transparent;
  border: 1px solid #121212;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  cursor: pointer;
  outline: none;
  margin: 30px auto 0;
  width: 100%;
  text-decoration: none !important;
}

.single__section {
  padding: 20px;
  border-left: 0.5px solid #7e7e7e2e;
}

.single__section h4 {
  color: #7e7e7e;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin: 13px 0 9px;
}

.single__section ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 7px;
}

.single__section ul li {
  color: #222222;
  border: 0.57px solid #e5e5e5;
  background-color: #fbfcfc;
  border-radius: 50px;
  padding: 6px 7px;
  font-size: 11px;
  line-height: 11px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.single__section h6 {
  font-size: 11px;
  font-weight: 700;
  color: #2b79ed;
  line-height: 11px;
  margin-top: 12px;
}

@media screen and (min-width: 700px) {
  .outer-cont {
    padding: 60px 0;
    /* margin-top: 20px; */
  }
  .step-cont {
    margin-top: 50px;
  }
  .step-text-cont h2,
  .pricing__title__cont h2 {
    font-size: 30px;
    line-height: 37px;
  }
  .step-text-cont p,
  .pricing__title__cont p {
    font-size: 18px;
    line-height: 26px;
  }

  .step {
    width: 48.6%;
  }

  .started-innovation-button {
    width: 205px;
    min-width: 205px;
    margin-top: 40px;
  }
  .pricing__plans {
    margin-top: 40px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }
  .pricing__section__cont {
    padding: 55px 0 35px;
  }
}

@media screen and (min-width: 1200px) {
  .outer-cont {
    padding: 80px 0 60px;
  }
  .step {
    width: 24%;
  }
  .step-text-cont h2,
  .pricing__title__cont h2 {
    font-size: 36px;
    line-height: 43px;
    text-align: center;
  }
  .step-text-cont p,
  .pricing__title__cont p {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
  .started-innovation-button {
    margin-top: 50px;
  }
}
