.pricing{
    .button-gold{
        background:#F7C948;
        color:#fff;
    }
    .top-section{
        background:#FFFAEB;
        padding:42px 70px 300px 70px;
        .navigation{
            display:flex;
            justify-content:space-between;
            margin-bottom:105px;
            .logo{
                img{
                    width:164px;
                }
            }
        }
        .head{
            padding:0 423px;
            text-align:center;
            .title{
                font-size:64px;
                color:#8D2B0B;
            }
            .subtitle{
                font-size:24px;
                padding:0 43px;
                margin-top:32px;
            }
        }
    }
    .bottom-section{
        padding:0 70px 226px 70px;
        .table{
            position:relative;
            top:-250px;
            .list{
                display:flex;
                justify-content: space-between;
                .item{
                    padding:50px 50px 58px 50px;
                    width:303px;
                    background:#fff;
                    text-align:center;
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
                    border-radius: 6px;
                    .name{
                        font-size:24px;
                        margin-bottom:40px;
                    }
                    .price{
                        color:#F7C948;
                        font-size:18px!important;
                        .value{
                            font-size:48px;
                            font-weight:600;
                        }
                    }
                    .features{
                        margin:32px 0 60px 0;
                    }
                }
            }
            .subtitle{
                margin-top:56px;
                font-size:24px;
                text-align:center;
            }
        }
        .faq{
            padding:0 188px;
            margin-bottom:104px;
            .head{
                margin-bottom:112px;
                text-align:center;
                .title{
                    margin-bottom:24px;
                    font-size:40px;
                }
            }
            .list{
                .item{
                    border-bottom: 1px solid #CFCFCF;
                    padding:46px 0;
                    .pagination{
                        font-size:24px;
                        display:flex;
                        justify-content:space-between;
                    }
                    .text{
                        margin-top:32px;
                    }
                }
                .item-close{
                    .text{
                        height:0;
                    }
                }
            }
        }
        .more-questions{
            padding:57px 97px 111px 97px;
            background:#FFFAEB;
            border-radius:20px;
            display:flex;
            justify-content: space-between;
            .text{
                display:inline-block;
                .title1{
                    font-size:48px;
                    color:#FADB5F;
                    font-weight:600;
                }
                .title2{
                    font-size:48px;
                    color:#CB6E17;
                    font-weight:600;
                }
            }
            .button-container{
                display:inline-block;
            }
        }
    }
    
}