/* dialog box */
.dialog-box {
  font-size: 16px;
  color: #626262;
}
.dialog-box h3 {
  font-weight: 600;
  font-size: 24px;
}
.dialog-box .Component-root-55 {
  padding: 5px;
}
.dialog-box .divider {
  margin: 32px 0;
}
.dialog-box .form {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-right: 45px;
}
.dialog-box .label {
  padding-top: 20px;
}
.dialog-box .label-two {
  padding-top: 10px;
}
.dialog-box .actions {
  text-align: right;
  margin: 10px 20px 40px 0;
}

.switch_btn {
  border: 1px solid #cfcfcf !important;
  width: 361px;
  height: 40px;
  padding: 0 0 0 3% !important;
  background-color: white;
}

.filter_style{
    background-color: #F7F7F7;
    width: 53px;
    height: 18px;
    border-radius: 13px;
    padding-bottom: 21px;
}
@media screen and (max-width: 768px) {
  .input_cont {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .org_title{
    font-size: 16px;
  }
}
