.container {
  box-shadow: 0px 4px 74px rgba(31, 36, 49, 0.05);
  border-radius: 5px;
  width: 100%;
  max-width: 1000px;
}

.header_section {
  display: grid;
  grid-template-columns: 400px 1fr;
  width: 100%;
  align-items: center;
}

.header_section > div:first-of-type {
  text-align: center;
  background-color: #f7c948;
  color: #fff;
  height: inherit;
  padding: 15px 0;
  clip-path: polygon(0 0, 100% 0, 80% 98%, 0% 100%);
}

.header_section > div:last-of-type {
  text-align: center;
  width: 100%;
  padding: 15px 0;
}

/* overide bootstrap default style */
.header_section > * p {
  margin: 0 !important;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}

.body_section {
  padding: 33px;
  width: 100%;
}

.section_container {
  width: 100%;
}

.disabled_input {
  background-color: transparent !important;
  height: 48px;
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: bold;
}
