*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: white !important;
}

.sign_up_form_group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.sign_up_form_group p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom__container__80 {
  margin: 0 auto;
  width: 80%;
}



textarea:hover,
input[type='text']:hover,
input[type='number']:hover,
input[type='password']:hover,
input[type='email']:hover,
textarea:active,
input[type='text']:active,
input[type='number']:active,
input[type='email']:active,
input[type='password']:active,
textarea:focus,
input[type='text']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='password']:focus,
button:focus,
button:active,
button:hover,
label:focus,
select:focus,
select:active,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ck-editor__editable_inline {
  min-height: 650px !important;
}

/* Override bootstrap nav pills styles in OrphanSetting.js*/
.orphaneSetting_Tabs_Container li a {
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid rgba(42, 52, 89, 0.1);
  border-radius: 0 !important;
  color: #616e7c !important;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 19px;
}

.orphaneSetting_Tabs_Container li a.nav-link {
  border-bottom: 1px solid rgba(42, 52, 89, 0.1) !important;
  color: rgba(24, 25, 27, 0.9);
  height: 35px;
}

.orphaneSetting_Tabs_Container li a.nav-link.active {
  border-bottom: 2px solid #f7c94a !important;
  color: rgba(24, 25, 27, 0.9) !important;
}

.red-border {
  border: 1px solid red;
}

.font-weight-medium {
  font-weight: 500;
}

/* ovveride default dialog styles in delete template */
body > div.MuiDialog-root.deletetemplate_override__2n8-T > div.MuiDialog-container.MuiDialog-scrollPaper > div {
  position: relative;
}

body > div.MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper > div {
  position: relative;
  border-radius: 8px;
}

/* ovveride default expansion panel styles in print editor sidebar */
body * > .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.canvas_container {
  position: relative;
  width: 97%;
  margin: 0 auto;
}

/* remove extra spacing on print templates table header */
.templates_table_container .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-6.MuiToolbar-gutters,
.templates_table_container .MuiToolbar-root.MuiToolbar-regular.jss6.MuiToolbar-gutters {
  display: none !important;
}
