.override div.MuiDialog-container.MuiDialog-scrollPaper > div {
  background-color: #fff;
}

.titleContainer {
  padding: 10px 0;
  color: #000;
  border-bottom: 1px solid #b1b1b1;

  h6 {
    margin-right: 20px;
  }
}

.contentContainer {
  padding: 0px 20px 20px 20px;
  min-height: 700px;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.blueButton {
  background-color: rgb(2, 119, 189);
  color: #fff;
  padding: 10px !important;

  &:hover {
    color: #fff;
    background-color: rgba(2, 119, 189, 0.9);
  }
}

.container {
  width: 710px;
  overflow-y: scroll;
}

.formContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  input {
    appearance: none;
    border: 1px solid #cdcdcd;
    outline: none !important;
    padding: 3px;
    background-color: #fff;
    height: 30px;
  }

  textarea {
    padding: 3px;
    border: 1px solid #cdcdcd;
  }

  button {
    height: 44px;
  }
}

.inputRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 12px 15px;
  margin-bottom: 16px;
}

.buttonContainer {
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}

.titleContainer {
  padding: 10px 0;
  color: #000;
  display: flex;
  align-items: center;

  h6 {
    margin-right: 20px;
  }
}

.orderbutton {
  appearance: none;
  padding: 11px;
  font-size: 14px;
  line-height: 14px;
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0;

  &.active {
    background-color: #e7eefd;
    border-color: #e7eefd;
  }
}
