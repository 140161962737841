.bigBox {
  display: flex;
  justify-content: center;
}
.boxContainer {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  min-height: 50vh;
  margin: 30px;
  /* text-align: center; */
}
.imgCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.cardContainer {
  cursor: pointer;
}
.container.dashboardview .thatapp-card-container > div {
  height: 100% !important;
}
@media screen and (max-width: 1100px) {
  .boxContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .boxContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 500px) {
  .boxContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}
.cardClass {
  display: block;
  text-align: center;
}
/* .cardClass:hover {
  background-color: #f8f8f8;
} */
.cardDiv {
  width: 90%;
  border: 1px solid #eee;
  height: 40px;
  margin: 5px auto;
  padding: 10px;
}

.reputation {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.reputation i {
  font-size: 12px;
}
.alertBd {
  display: flex;
  /* justify-content: space-evenly; */
}

.alertBd h6 {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 0.5em;
}

.multi {
  border: 1px solid #eee;
  border-bottom: none;
  background-color: #fff;
  height: 120px;
}
.multi img {
  margin: 20px auto;
  width: 30%;
}

.multi img:hover {
  background: #339f51;
}

.multiBtm {
  background: #339f51;
  height: 20px;
  color: #fff;
}
.multiLink {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.multiLink:hover {
  text-decoration: none;
  color: #fff;
}
.multiApp {
  border: 1px solid #eee;
  margin: auto;
  background-color: #fff;
  height: 120px;
  color: #666;
}

.multiApp:hover {
  background-color: #f8f8f8;
}
.multiApp h2 {
  margin: auto;
  text-align: center;
}

.latestCard {
  display: block;
  text-align: center;
}

.latestCardDiv {
  width: 90%;
  border: 1px solid #eee;
  height: 50px;
  margin: 5px auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.cardFlow {
  width: 99%;
  height: 10%;
  overflow: auto;
  overflow-y: hidden;
}

.Question {
  box-sizing: border-box;
  display: flex;
  justify-content:flex-start;
  align-items:flex-start;
  border: 1px solid red;
}