#connector-modal {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
#connector-modal #button-save {
  background: #f7c948 !important;
  border-radius: 3px !important;
  color: #ffffff;
  margin: 0px 10px;
}

.tooltiptext {
  font-size: 0.7rem !important;
}
.push-tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.push-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.push-tooltip:hover .tooltiptext {
  visibility: visible;
}

.MuiDialog-paperWidthMd {
  max-width: 60% !important;
}
.connector-form {
  /* padding: 0 50px 0 50px; */
  display: flex;
}
.connector-form .connector-form-field {
  display: flex;
  align-items: center;
}
#outlined-basic {
  width: 380px !important;
}
#outlined-basic .MuiOutlinedInput-root {
  height: 40px !important;
}
.connector-form-field span {
  margin-right: 50px;
}
#demo-simple-select {
  width: 100% !important;
}
.MuiInput-root {
  width: 100% !important;
}
.map-data-button {
  background: #f7c948;
  border-radius: 5px;
  border: #fff3c4 !important;
  color: white !important;
}

.box,
.left-box {
  height: 500px !important;
  padding: 15px !important;
  padding-top: 0 !important;
  overflow-y: scroll;
}

.box,
.left-boxM {
  height: 400px !important;
  padding: 15px !important;
  padding-top: 0 !important;
  overflow-y: scroll;
  margin-bottom: 10%;
}

.box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  margin-right: 3px;
}
.box::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.progress-div {
  width: fit-content;
  margin: auto;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #de911d !important;
}

.tab {
  cursor: pointer;
  background-color: #f7f7f7;
  padding: 1%z 3%;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: space-between;
  width: 100%;
  margin-top: 3%;
}

.tab > .active-left {
  /* background: url("../../../assets/images/yellow-bg.jpg") 100%; */
  color: #ffffff;
  background-size: cover;
  background-color: #f7c948;
  border-radius: 50px;
  text-align: center;
  padding: 5% !important;
  width: 50%;
}

.tab > .active-right {
  color: #ffffff;
  background-size: cover;
  background-color: #f7c948;
  border-radius: 50px;
  text-align: center;
  padding: 5% !important;
  width: 50%;
}

/* for mobile screen */
.tabM {
  cursor: pointer;
  background-color: #f7f7f7;
  padding: 2% 3%;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: space-between;
  width: 100%;
  margin-top: 3%;
  font-size: 13px;
  
}

.tabM > .active-leftM {
  /* background: url("../../../assets/images/yellow-bg.jpg") 100%; */
  color: #ffffff;
  background-size: cover;
  background-color: #f7c948;
  border-radius: 50px;
  text-align: center;
  padding: 3% 5% !important;
  width: 50%;
  
}

.tabM > .active-rightM {
  color: #ffffff;
  background-size: cover;
  background-color: #f7c948;
  border-radius: 50px;
  text-align: center;
  padding: 3% 5% !important;
  width: 50%;
}

/* for Edit Modal */
.tabE {
  cursor: pointer;
  background-color: #f7f7f7;
  padding: 1% 1%;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: space-between;
  width: 100%;
  margin-top: 3%;
  font-size: 13px;
  
}

.tabE > .active-leftE {
  /* background: url("../../../assets/images/yellow-bg.jpg") 100%; */
  color: #ffffff;
  background-size: cover;
  background-color: #f7c948;
  border-radius: 50px;
  text-align: center;
  padding: 3% 5% !important;
  width: 50%;
  
}

.tabE > .active-rightE {
  color: #ffffff;
  background-size: cover;
  background-color: #f7c948;
  border-radius: 50px;
  text-align: center;
  padding: 3% 5% !important;
  width: 50%;
}

.MuiTypography-body1 {
  font-size: 0.8rem !important;
}
.MuiDialog-paper {
  overflow: hidden !important;
}

.viewFields .MuiDialog-paperWidthSm {
  max-width: 100% !important;
}

.viewFieldscont .MuiDialog-paperWidthSm {
  max-width: 60% !important;
}

.mapModalFooter {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  gap: 2rem !important;
}

.mapModalFooter_dbs {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 1rem;
}
/* .MuiIconButton-label {
  color: #cfcfcf;
  border-radius: 4px;
  margin: 0 10px;
} */
.mapper_grid_table{
  overflow-x: auto;
  width: 90vw !important;
}

@media only screen and (max-width: 768px) {
  .mapper_field_text{
    font-size: 18px !important;
  }
  .box{
    height: 350px;
  }
}