.override div.MuiDialog-container.MuiDialog-scrollPaper > div {
  background-color: #fff;
}

.container {
  width: 500px;
}

.container section {
  padding: 0 30px;
  margin-bottom: 39px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #7e7e7e;
}

.button {
  appearance: none;
  border: none;
  font-size: 12px;
  margin-bottom: 0 !important;
  color: #fff;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}

.org_container {
  overflow-y: scroll;
}

.org_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .org_wrapper {
    display: flex;
    max-width: 300px;
    flex-wrap: wrap;
    align-items: center;
  }

  p {
    font-size: 16px;
    margin-bottom: 0 !important;
    color: #040707;

    &.org_name {
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 2px;
    }

    &.org_id {
      font-weight: bold;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  button {
    appearance: none;
    height: 34px;
    margin: 0 !important;
    font-size: 13px !important;
    align-self: flex-end !important;
    margin-left: auto;
  }
}

.dot_contianer {
  display: flex;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #f7c948;
}
