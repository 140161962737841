.container.dashboardview .thatapp-card-container > div {
  height: 100% !important;
}
.container.dashboardview .row {
  row-gap: 1.5rem;
}
.applist-app-container {
  height: 200px;
  width: 95%;
  transition: display 0s, opacity 0.5s linear;
  background: #eee;
}
.applist-app-container h5 {
  color: #337ab7;
}

.applist-app-container:hover > .applist_topIcon,
.applist-app-container:hover > .project_details {
  display: none;
}

.iconWrapper {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* inset: auto; */
  margin: 0 auto;
}

.wrapper {
  display: none;
}

.applist-app-container:hover > .wrapper {
  display: flex;
  flex-direction: column;
}

.app-list-add-new {
  border: 2px dashed #337ab7;
  height: 200px;
  width: 90%;
}

/* .app-list-icon {
  color: #7c7c7c;
} */

.tmail-apps-list .rounded {
  cursor: pointer;
}

.general_container {
  display: flex;
}

.orphan_tab_container {
  width: 100%;
}

.orphan_tab_container .content {
  display: flex;
  width: 65%;
}

.headings {
  width: 37%;
  display: flex;
  justify-content: space-between;
}

.headings i {
  margin-top: 10px;
}

.headings p {
  font-size: 1.4rem;
}

.orphan_tab_container .content .left {
  background-color: #f5f5f5;
  width: 30%;
  padding: 2rem;
  border-radius: 8px;
  height: 400px;
}

.orphan_tab_container .content .left ul li {
  list-style: none;
  padding: 0.9rem 2rem;
}

.right {
  padding: 2rem;
  width: 100%;
}

.right select {
  width: 100%;
  height: 35px;
  border-radius: 3px;
  border: 1px solid #ced4da;
  color: #7e5e5c;
  padding-left: 5px;
  margin-bottom: 1rem;
}