.create_table_title {
  font-size: 18px;
  margin-top: 10px;
}

.back_button {
  appearance: none;
  border: none;
  background-color: #fff;
  height: 30px;
  padding: 0;
  text-align: left;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  i {
    padding-right: 4px;
  }
}

.helper_text {
  font-size: 12px;
  margin-top: 4px;
}

.change_button {
  margin-top: 20px;
  border: 1px solid;
  font-family: 'Mulish', sans-serif;
  font-size: 16px !important;
  text-align: center;
  position: relative;
  padding: 5px 0 !important;
  appearance: none;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.fieldGroup {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
}
