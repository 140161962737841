.dside .MuiPaper-root {
  background: #f27649;
  color: #fff;
  padding-bottom: 20px;
  padding-right: 10px;
  margin-top: 50px;
}

.dside .material-icons {
  color: #fff;
}

.dside .MuiTypography-body1 {
  color: #fff;
  font-size: 14px;
}

.dside .MuiList-root {
  border: none;
  position: relative;
}

.dside .sidebar__header {
  width: 100%;
  margin-top: 28px;
  /* background: #fff; */
}

.dside .sidebar__header img {
  margin-left: 5px;
}

.dside .logo__title {
  font-weight: bold;
  font-size: 16px;
  /* margin-left: 10px; */
}

.dside .MenuNode-nested-15 {
  padding-left: 72px !important;
}

.dside .MenuNode-subNested-16 {
  padding-left: 80px !important;
}

.dside .MenuCollapse-nested-17 {
  padding-left: 72px !important;
}

.dside .sidebar__content {
  margin-top: 40px;
}

.dside .sidebar__content .subheading {
  font-size: 13px;
  margin-left: 15px;
  margin-bottom: 20px;
}

.dside .sidebar__content .list__item {
  font-size: 14px;
  margin-left: 8px;
}

.dside .sidebar__content .MuiListItem-root {
  padding: 10px 20px;
  margin: 0 0px;
  border-radius: 0 24px 24px 0;
  padding-left: 20px;
}

.dside .sidebar__content .MuiListItem-root.active {
  background: #f27649;
  border-radius: 0 24px 24px 0;
  padding: 10px 20px;
  margin: 0 0px;
  padding-left: 20px;
}

.dside .sidebar__content .MuiListItem-root img {
  width: 20px;
  margin-right: 10px;
}

.dside .sidebar__content .MuiListItem-root .file-img {
  width: 17px;
}

.dside .sidebar__content .MuiListItem-root:hover {
  background: #f27649;
  border-radius: 0 24px 24px 0;
  padding: 10px 20px;
}

.dside .banner {
  margin-top: 20px;
}

.dside .banner img {
  width: 100%;
  margin: 0 auto 10px auto;
}

.dside .banner .title {
  color: #f7f7f7;
  font-weight: 600;
  margin-block: 0;
}

.dside .banner .description {
  font-size: 14px;
  margin-top: 5px;
}

.dside .banner a {
  border: none;
  background: #f27649;
  border-radius: 3px;
  color: #fff;
  width: 100%;
  padding: 10px;
  display: block;
}

.dside .sidebar__content .hidden {
  display: none;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent !important; /* make scrollbar transparent */
}

/* dialog box */
.dialog-box {
  font-size: 16px;
  color: #626262;
}
.dialog-box h3 {
  font-weight: 600;
  font-size: 24px;
}
.dialog-box .Component-root-26 {
  padding: 5px;
}
.dialog-box .divider {
  margin: 32px 0;
}
.dialog-box .form {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-right: 45px;
}
.dialog-box .form .label {
  font-weight: 600;
  padding-top: 15px;
}
.dialog-box .actions {
  text-align: right;
  margin: 40px 45px 0px 0;
}
