.confirm-modal-container {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  float: right;
}
.modal__two__component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(7, 7, 7, 0.76);
  z-index: 1;
}
.modal__two__component .modal__two__content {
  position: fixed;
  justify-content: center;
  height: 100%;
  color: #fff;
  margin-top: 0;
  z-index: 10;
}
.modal__two__component .modal__two__content .close__modal {
  margin: 10px 0;
  cursor: pointer;
}
.modal__two__component .modal__two__content .modal__two__title {
  margin: 10px 0;
  color: #fcfcfc;
}
.modal__two__component .modal__two__content .modal__two__card {
  background: #fff;
  width: 20vw;
  border-radius: 3px;
  padding: 20px 20px;
  margin-top: 50px;
  color: #3b3b3b;
  position: fixed;
  top: 0;
  margin: 0 auto;
  left: 40vw;
  text-align: center;
  font-weight: bold;
  z-index: 10;
}
.modal__two__component .modal__two__content .modal__two__card P {
  margin-top: 10px;
}
.modal__two__component .modal__two__content .modal__two__card > div {
  margin-bottom: 20px;
}
.modal__two__component .modal__two__content .modal__two__card form > div {
  margin-bottom: 20px;
}
.modal__two__component .modal__two__content .modal__two__card .confirm__header {
  color: #03293d;
  text-align: center;
  font-size: 20px;
  margin: 40px 0;
}
.modal__two__component .modal__two__content .modal__two__card button {
  margin-top: 20px;
  background: #2871b6;
  color: #fff;
  border: none;
  width: 100%;
  padding: 10px 30px;
  border-radius: 4px;
}
.modal__two__component .modal__two__content .modal__two__card {
  color: #ef4e4e;
  margin-top: 30px;
  cursor: pointer;
}
