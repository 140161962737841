.connector {
  /*background: #fffaeb;*/
  width: 100%;
  /*height: 100vh;*/
}

.container .logo {
  margin: auto !important;
}

.logout {
  text-align: right;
}

.connector .header {
  padding-top: 30px;
}

.connect.header {
  padding-top: 30px;
  vertical-align: middle;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.connector .welcome__section {
  margin: 20px 0;
  text-align: center;
}

.connector .welcome__section .title {
  color: #8d2b0b;
  font-size: 40px;
}

.connector .welcome__section .description {
  color: #626262;
}

.connector .connectors {
  margin: 30px 0;
}

.connector .connectors .connector__box {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 10px;
  position: relative;
  height: 260px;
  cursor: pointer;
}

.connector .connectors .connector__box .label.success {
  color: #27ab83;
  background: #effcf6;
  border-radius: 12.5px;
  font-size: 12px;
  padding: 0 5px;
  width: 33%;
}

.connector .connectors .connector__box .label.failure {
  color: #e12d39;
  background: #ffe3e3;
  border-radius: 12.5px;
  font-size: 12px;
  padding: 0 5px;
  width: 36%;
}

.connector .connectors .connector__box .label.unavailable {
  color: #626262;
  background: #e1e1e1;
  border-radius: 12.5px;
  font-size: 12px;
  padding: 0 5px;
  width: 36%;
}

.connector .connectors .connector__box .image__container {
  height: 150px;
}

.connector .connectors .connector__box img {
  width: 80px;
  position: relative;
  left: 30%;
  margin: 20px 0 50px 0;
}

.connector .connectors .connector__box img.salesforce {
  width: 120px;
}

.connector .connectors .connector__box img.google {
  width: 130px;
  left: 25%;
  top: 30px;
}

.connector .connectors .connector__box img.ebay {
  width: 130px;
  left: 25%;
  top: 30px;
}

.connector .connectors .connector__box img.dropbox {
  width: 90px;
  left: 25%;
  top: 10px;
}

.connector .info {
  padding-bottom: 50px;
  color: #626262;
}
