//right sidebar
.right_container {
  overflow-y: scroll;
  padding-bottom: 30px;
}

.buttons_container {
  margin: 0 auto;
  padding: 0 20px;

  & > button {
    border: 1px solid;
    width: 100%;
    font-family: 'Mulish', sans-serif;
    font-size: 16px !important;
    text-align: center;
    position: relative;
    padding: 10px 0 !important;
    margin: 0;
    appearance: none;
    border-radius: 4px;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    i.absolute {
      font-size: 12px;
      position: absolute;
      right: 15%;
      top: 53%;
      transform: translateY(-50%);
    }

    &:disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
    }
  }
}
