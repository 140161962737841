.raw__data .card {
  border: none;
  border-radius: 10px;
  margin: 20px 0;
}

.raw__data .card-header {
  cursor: pointer;
  background: #fff;
}

.raw__data .card h2 {
  cursor: pointer;
  font-size: 24px;
  background: #fff;
  padding: 10px 0;
}

.raw__data .card-body .data {
  margin-bottom: 30px;
}

.raw__data .card-body .data p {
  margin: 5px 0;
}

.raw__data .card-body .data a {
  color: #f7c948;
}

@media only screen and (max-width: 600px) {
  .raw-data-container {
    flex-direction: column;
  }

  .raw-data-header{
    width: 100% !important;
  }

  .raw-data-filter-work{
    width: 100% !important;
    padding-top: 5% !important;
    margin-top: 10% !important;
  }
  .copy-cards{
    width: 80vw;
  }
}
