.payment_cards_container {
  border-bottom: 1px solid #e8e8e8;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 9px;

  .header {
    padding: 17.5px 0;
    border-bottom: 1px solid #e8e8e8;

    p {
      margin: 0;
      font-weight: bold;
      padding: 0 15px;
    }
  }
}

.select_payment_card {
  appearance: none;
  width: 100%;
  height: 63px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  border: none;
  margin: 8px 0;
  color: #000;

  span {
    margin-left: auto;
  }

  &:hover,
  &:active {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05) !important;
  }
}
