.sync__btn {
  color: #fff;
  background: #f7c948;
  border-radius: 6px;
  border: none;
  padding: 15px 30px;
}
.sync__btn2 {
  color: #fff;
  background: #f7c948;
  border-radius: 6px;
  border: none;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.text-primary {
  color: black !important;
}