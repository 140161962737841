.footer-cont {
  padding: 0 0 35px 0;
  z-index: 10;
}

.footer-get-started {
  padding: 70px 5vw;
  background-image: url("../../Images/footer-bg.png");
  background-size: cover;
}

.footer-get-started a {
  text-decoration: none;
}

.footer-get-started h2 {
  color: #010e20;
  font-size: 24px;
  line-height: 30px;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  text-align: center;
}

.footer-get-started p {
  color: #010e20;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  max-width: 544px;
  margin: 10px auto 15px;
  text-align: center;
}

.footer-get-started a {
  background-color: #010e20;
  border-radius: 50px;
  border: none;
  outline: none;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  width: 60%;
  margin: 0 auto;
  min-width: 211px;
  max-width: 233px;
  text-decoration: none !important;
}

.footer-get-started a:hover {
  opacity: 0.85;
}

.footer-get-started a p {
  font-size: 15px;
  line-height: 24px;
  color: white;
  margin: 0;
  font-family: "Rubik", sans-serif;
}

.footer-get-started a img {
  width: 24px;
  height: 24px;
}

.footer-links {
  width: 100%;
  padding: 0 2%;
  max-width: 1330px;
  margin: 35px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-bottom: 20px;
}
.footer-logo-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer-logo-cont img {
  width: 145px;
}
.footer-logo-cont p {
  color: #010e20;
  font-size: 16px;
  line-height: 24px;
  margin-top: 6px;
  text-align: center;
  width: 80%;
}

.other-footer-links {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 45%;
}

.other-footer-links a:hover,
.other-footer-links div:hover {
  text-decoration: underline;
  text-decoration-color: #010e20 !important;
}

.other-footer-links h2 {
  color: #010e20;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.thatapp-info {
  padding: 20px 0 30px 0;
  display: none;
  text-align: center;
}

h6,
.thatapp-info,
.other-footer-links p {
  color: #010e20;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.other-footer-links p {
  margin-bottom: 0;
}

h6 {
  text-align: center;
}

@media screen and (min-width: 700px) {
  .footer-cont {
    padding: 0 0 40px 0;
  }
  .footer-get-started h2 {
    font-size: 34px;
    line-height: 44px;
  }
  .footer-get-started p {
    font-size: 16px;
    line-height: 24px;
  }
  .footer-get-started {
    padding: 80px 5vw;
  }
  .footer-links {
    margin: 40px auto;
    gap: 40px 30px;
    width: 90vw;
  }
  .thatapp-info {
    display: initial;
  }
}

@media screen and (min-width: 1200px) {
  .footer-cont {
    padding: 0 0 40px 0;
  }
  .footer-get-started h2 {
    font-size: 40px;
    line-height: 56px;
  }
  .footer-get-started p {
    font-size: 18px;
    line-height: 26px;
    max-width: 564px;
  }
  .footer-get-started {
    padding: 100px 5vw;
  }
  .footer-links {
    flex-wrap: nowrap;
    gap: 40px;
    margin-top: 60px;
  }
  .footer-logo-cont {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  .footer-logo-cont img {
    width: 193px;
  }
  .footer-logo-cont p {
    text-align: left;
    width: 80%;
  }

  .other-footer-links {
    text-align: left;
    justify-content: flex-start;
    margin: 0 auto;
  }

  h6,
  .thatapp-info {
    text-align: initial;
  }
}
