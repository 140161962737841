.billing_cont {
  padding: 16px 5vw 20px;
  width: 100vw;
  min-height: 100vh;
}

.billing_content_cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.billing_content {
  width: 100%;
  max-width: 790px;
  margin-top: 35px;
}

.billing_header h4 {
  text-align: center;
  font-size: 22px;
  line-height: 30px;
}

.billing_header p {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #7e7e7e;
}

.billing_plan-v2 {
  border: 1px solid #e5e5e5;
  border-radius: 14px;
  background-color: #fbfcfc;
  padding: 20px;
}

.billing_plan-v2 ul {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.billing_plan-v2 ul li::marker {
  color: yellow;
  font-size: 20px;
}

.billing_plan {
  border: 1px solid #e5e5e5;
  border-radius: 14px;
  background-color: #2b79ed;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3px;
}

.services__title__cont {
  margin-top: 25px;
}

.billing_hr {
  margin: 20px 0;
}

.billing_trial_hr {
  margin: 30px 0;
}

.billing__pricing__cont {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 14px;
}

.billing__total__cont {
  width: 100%;
  margin-top: 5px;
}

.billing_plan_feature {
  border: 1px solid #e5e5e5;
  border-radius: 14px;
  background-color: #fff;
  padding: 2%;
  text-align: center;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.billing_base {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: #fff;
  padding: 6px 13px;
  width: 49%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.billing_info {
  border: 1px solid #f2b11b;
  background-color: #fffbf1;
  border-radius: 14px;
  padding: 16px;
}

.option_head {
  font-weight: 700;
  color: #f2b11b;
}

.billing_amt {
  font-weight: 800;
  font-size: 20px;
}

.billing__feature__price__cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 9px;
}

.billing__radio__cont {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.radio__p {
  font-size: 16px;
  line-height: 16px;
}

.billing__button__cont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.billing__button__cont button,
.billing__btn {
  width: 100%;
  border-radius: 4px;
}

@media screen and (max-width: 600px) {
  .billing_content {
    width: 100%;
  }

  .billing_base {
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .billing_content {
    width: 100%;
  }

  .billing_cont {
    padding: 20px 5vw 50px;
  }

  .billing_header h4 {
    font-size: 24px;
    line-height: 30px;
  }

  .billing__pricing__cont {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .billing_hr {
    margin: 35px 0;
  }

  .services__title__cont {
    margin-top: 30px;
  }

  .billing__total__cont {
    width: fit-content;
    margin-top: 0;
  }

  .billing__radio__cont {
    flex-direction: row;
    gap: 30px;
    align-items: center;
  }

  .billing__button__cont {
    flex-direction: row;
    justify-content: space-between;
  }

  .billing__button__cont button,
  .billing__btn {
    width: fit-content;
  }
}

@media screen and (min-width: 800px) {
  .billing_content {
    box-shadow: 5px 5px 20px rgba(189, 187, 187, 0.1);
    border-radius: 20px;
    padding: 2%;
  }

  .billing__pricing__cont {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}