/* .svg-element{
    position:relative;
    width:100%;
    height:100%;
    background:#fff;
} */

.iris-tabs {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: flex-end;
  gap: 10px;
  z-index: 1000;
  position: relative;
}

.iris-strikethrough {
  text-decoration: line-through;
}

.iris .svg-container {
  overflow-x: scroll !important;
  display: flex;
  justify-content: center;
}
.iris-tabs .item {
  position: relative;
  text-align: left;
  max-width: 210px;
  grid-column: auto;
  padding: 2vh 0;
}
.iris-tabs .item button {
  width: 100%;
}
.iris-tabs .item img {
  height: 5vh;
  margin-bottom: 2vh;
}
.iris-tabs .item .label {
  font-size: 14px;
  line-height: 18px;
  color: #9e9e9e;
  margin-bottom: 5px;
}
.iris-tabs .item-select {
  // background: #fff;
  width: 100%;
}
.manager-tabs {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  z-index: 1000;
  position: relative;
}
.manager-tabs .item {
  text-align: left;
  width: 50%;
  padding: 2vh 0;
}
.iris .button-container {
  text-align: right !important;
  width: 100%;
  padding-top: 4vh;
}
.iris button {
  background: #f7c948;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1vh 1vw;
  outline: none;
  text-decoration: none;
  border: none;
  font-size: 1vw;
  color: #fff;
  width: 100%;
  float: right !important;
  width: 133px;
  height: 48px;
  box-shadow: none;
}

.iris .iris-container {
  padding-bottom: 5vh;
  margin: 2.5vh 0 5vh 0;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 0 30px;
}

.iris .iris-container .content {
  background: rgba(0, 0, 0, 0.1);
  height: 75vh;
  overflow: hidden;
}

.iris .iris-container .content .inner-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.iris .iris-container .content .inner-content #loader-holder {
  display: flex;
  justify-content: center;
  background: #f7f7f7;
  width: 100%;
  height: 100%;
}

.iris .iris-container .filter {
  height: 75vh;
  overflow-x: scroll;
  display: grid;
  grid-template-rows: 0.5fr 0.5fr;
}

.iris .iris-container .filter .tab-container {
  background: #fff;
  margin: 0;
  padding: 16px 26px;
}
.iris .iris-container .filter .tab-container .title {
  font-size: 24px;
  line-height: 30px;
  color: #666666;
  margin-bottom: 24px;
}

/* .
.iris .iris-container .filter .tab-container .tab {
  margin-top: 0vh;
  font-size: 0.9vw;
  border: 1px solid red;
  display: block;
  width: 100%;
}
.iris .iris-container .filter .tab-container .tab ol {
  margin: 15px;
}
.iris .iris-container .filter .tab-container .tab .label {
  margin-top: 0vh;
  color: #9e9e9e;
} */
.iris .iris-container .filter .settings-container .tab input {
  width: 100%;
  background: #f7c948;
  color: #f7c948;
}
.iris .iris-container .filter .filter-container {
  margin-top: 3vh;
}
.iris .iris-container .filter .filter-container .tab input {
  width: 1vw;
  margin-right: 1vw;
  display: inline-block;
  background: #f7c948;
  color: #fff;
  /* border: 1px solid red; */
}

/* .iris .iris-container .filter .accordion {
  box-shadow: none !important;
  padding: 0 !important;
  border-bottom: 1px #000 solid;
  font-size: 0.9vw !important;
} */
/* .iris .iris-container .filter .accordion .tabs [label] {
  padding: 0 !important;
  font-size: 0.9vw !important;
} */
/* .note {
  margin-top: 5vh;
  color: grey;
} */

.sliders {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  margin-top: 24px;
}

.slider-container {
  display: flex;
  flex-direction: column;
}

.slider-container label {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #9e9e9e;
}
