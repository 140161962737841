button{
    padding:14px 40px;
    border-radius:3px;
    cursor:pointer;
    transition:.25s ease-in-out all;
    &:hover{
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        transition:.25s ease-in-out all;
    }
    img{
        margin-right:13px;
        height:15px;
    }
}
.button-2{
    padding:20px 40px;
    border-radius:15px;
}
.button-3{
    text-align:left;
    padding:20px 0;
}
.button-4{
    padding:10px 20px;
}
.button-image-container{
    display:flex;
    align-items:center;
    .button-container{
        display:inline-block;
    }
    img{
        margin-left:8px;
        height:18px;
    }
}

.dropdown-container{
    padding:20px 10px;
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    .dropdown-item{
        text-align:center;
        border-right:1px #dadada solid;
        padding:0 5px;
        cursor:pointer;
        &:last-child{
            border:none;
        }
    }
}

.dropdown-icon {
    display: flex;
    align-items: center;
    gap: 0px 10px;
}